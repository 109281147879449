.dropdown {
  display: flex;
  justify-content: space-between;
  &__toggle {
    position: relative;
    z-index: 1;
  }
  &__content {
    flex: 1;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
