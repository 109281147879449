.mini-cart-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: $boxSizeMargin;
  padding-bottom: $boxSizeMargin;
  background: $backgroundColor;

  @media screen and (min-width: $menuBreakpoint) {
    padding-top: $boxSizeMargin * 2;
    padding-bottom: $boxSizeMargin * 2;
  }
}
