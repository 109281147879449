@use "sass:math";

.menu-main-categories {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: math.div($boxSizeMargin, 2) $boxSizeMargin;
    font-weight: bold;
    font-size: $mediumSize;
    font-weight: bold;
  }
}
