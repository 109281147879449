@use "sass:math";

.tag {
  display: inline-block;
  background: $red;
  color: $white;
  padding: math.div($boxSizeMargin, 2) $boxSizeMargin;
  font-size: $smallSize;
  font-weight: bold;
  border-radius: $boxSizeMargin * 3;
}
