$spinDuration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  font-weight: normal;
}

.button {
  display: inline-block;
  background-color: $white;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $brandPrimary;
  color: $fontColor;
  border-radius: 2em;
  line-height: inherit;
  font-size: $regularSize;
  font-family: inherit;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.95rem 1.8rem 0.85rem;
  position: relative;
  transition: background-color ease-in-out 0.2s, border-color ease-in-out 0.2s;
  box-sizing: border-box;

  .inverted & {
    border-color: $shade03;
  }

  &:hover,
  &:focus {
    background: $shade02;
    text-decoration: none;
    outline: none;
    border-color: $shade06;
  }
}

.button--disabled {
  border-color: $shade03;
  &:hover,
  &:focus,
  &:active {
    background: $white;
  }
}

.button--ghost {
  color: $shade04;
  border-color: $shade03;

  &:hover,
  &:focus {
    color: $fontColor;
  }
}
.button--mini {
  padding: 0.4em 0.5em 0.3em;
}
.button--small {
  padding: 0.7em 0.9em 0.6em;
}
.button--big {
  font-size: $mediumSize;
  padding-left: 3.6rem;
  padding-right: 3.6rem;
}

.button--primary {
  background-color: $brandPrimary;
  border-color: $brandPrimary;

  .inverted & {
    border-color: $brandPrimary;
  }
  &:hover,
  &:focus {
    background: $shade03;
    border-color: $shade06;
    .inverted & {
      background: $white;
      border-color: $white;
    }
  }
}

.button--link,
.button--link-reverse {
  background: transparent;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  padding: 0.5rem;
  margin: -0.5rem;
  color: currentColor;
  text-transform: inherit;

  &:hover,
  &:focus {
    color: currentColor;
    background: transparent;
    text-decoration: underline;
  }
  &:focus-visible {
    outline: auto;
  }
  &.button--disabled {
    background: transparent;
  }
}

.button--warning {
  border-color: $warning;
  color: $warning;
}

.button--icon {
  display: block;
  margin: -0.5rem;
  padding: 0.5rem;
  border: none;
  color: inherit;
  background: transparent;
  border-color: $shade03;

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    color: $brandPrimary;
    background: transparent;
  }
  &:focus {
    outline: 0;
  }
}

.button--icon-block,
.button--icon-block-primary {
  padding: 0.5rem;
  > * {
    display: block;
  }

  &:focus {
    outline: 0;
  }
  &.button--big {
    padding: 0.8rem;
  }
}
.button--icon-block {
  border-color: $shade03;
}
.button--icon-block-primary {
  color: $white;
  background-color: $brandPrimary;
  border-color: $brandPrimary;

  &:hover,
  &:focus {
    color: $fontColor;
    background: $white;
    border-color: $shade02;
  }
}

.button--disabled {
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    cursor: not-allowed;
    border-color: $shade03;
    background: $white;
    color: $shade03;
    &.button--primary {
      border-color: $shade03;
      background: $shade03;
      color: $fontColor;
    }
  }
}

.button--pending {
  color: $white;
  cursor: pointer;

  &.button--primary {
    color: transparent;
  }
  &.button--warning {
    color: transparent;
    border: 1px solid $warning;
  }
  &.button--link {
    border: none;
  }
  &.button--icon {
    color: transparent;
    border: none;
    background: transparent;

    > * {
      color: transparent;
    }
  }
  &.button--icon-block-primary {
    color: transparent;
  }

  &:hover,
  &:focus {
    color: transparent;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    border: 1px solid $shade04;
    border-top-color: $shade05;
    animation: spin $spinDuration infinite linear;
  }
}
