@use "sass:math";

.form-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: math.div(-$boxSizeMargin, 2) (-$boxSizeMargin);

  &__element {
    margin: math.div($boxSizeMargin, 2) $boxSizeMargin;
  }

  &--only-one,
  &--center {
    justify-content: center;
  }

  &--start,
  &--start-small {
    justify-content: flex-start;
  }
  &--start-small {
    margin-left: math.div(-$boxSizeMargin, 2);
    margin-right: math.div(-$boxSizeMargin, 2);
    .form-actions__element {
      margin-left: math.div($boxSizeMargin, 2);
      margin-right: math.div($boxSizeMargin, 2);
    }
  }

  &--full {
    .form-actions__element {
      flex: 1;
      > * {
        width: 100%;
        display: block;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--vertical {
    flex-direction: column;
  }
}
