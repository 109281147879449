.order-shipment-tracking-products {
  width: 100%;

  thead {
    tr th {
      width: 20%;

      &:first-child {
        width: 60%;
      }
    }
  }

  th {
    text-align: left;
  }
}
