.storefront-content {
  position: relative;

  &__tools {
    color: #fff;
    font-size: 0.875rem;
  }

  &__edit-link {
    padding: 0.4rem 1rem 0.4rem 0.5rem;
    background: var(--source-brand-color);
    column-gap: 0.75rem;

    & > a {
      display: flex;
      align-items: center;
    }
  }

  &__edit-link-icon {
    font-size: 20px;
    line-height: 0;
    & > svg {
      width: 1em !important;
      height: 1em !important;
    }
  }
}

.storefront-content--block.storefront-content {
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: inherit;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 0px 3px var(--source-brand-color);
  }

  & > .storefront-content__tools {
    position: absolute;
    z-index: 9995;
    top: 0;
    left: 0;
  }

  &__initial-content {
    position: static;
  }
}

.storefront-content.storefront-content--page {
  & > .storefront-content__tools {
    z-index: 9995;
    left: unset;
    right: 0;
    position: fixed;
    top: 12.75rem;
  }
}
