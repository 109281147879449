@use "sass:math";

.category-navigation {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: -$boxSizeMargin;
    width: $boxSizeMargin;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba($white, 1) 0%,
      rgba($white, 0.75) 33%,
      rgba($white, 0.4) 67%,
      rgba($white, 0) 100%
    );
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: -$boxSizeMargin;
    width: $boxSizeMargin;
    z-index: 1;
    background: linear-gradient(
      270deg,
      rgba($white, 1) 0%,
      rgba($white, 0.75) 33%,
      rgba($white, 0.4) 67%,
      rgba($white, 0) 100%
    );
  }

  &__list {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    max-width: calc(100% + #{$boxSizeMargin * 2});
    margin: math.div(-$boxSizeMargin, 2) (-$boxSizeMargin);
    padding: 0;
    list-style: none;
    font-size: $mediumSize;
  }

  &__element {
    margin: 0;
    padding: math.div($boxSizeMargin, 2) $boxSizeMargin;
    white-space: nowrap;
  }
}
