.layer-page {
  &__aside {
    display: none;
  }

  @media screen and (min-width: $menuBreakpoint) {
    display: flex;
    margin: -$boxSizeMargin * 1.5;
    &__aside {
      width: 27%;
      min-width: 45ch;
      display: block;
      padding: $boxSizeMargin * 1.5;
    }
    &__main {
      padding: $boxSizeMargin * 1.5;
      width: 73%;
    }
  }
}
