@use "sass:math";

.select-menu {
  &__menu-list {
    background-color: $modalBackground;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid $modalBorderColor;
    border-radius: $boxSizeMargin;
    overflow: hidden;
  }
  &__menu-item {
    padding: math.div($boxSizeMargin, 1.5);
    &:hover {
      background-color: $modalBorderColor;
    }
    display: block;
    cursor: pointer;
  }
}
