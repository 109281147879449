@mixin progressStatus($key, $color) {
  &--#{$key} {
    color: $color;
    progress {
      background-color: rgba($color, 0.2);
      &::-webkit-progress-bar {
        background-color: rgba($color, 0.2);
      }
      &::-webkit-progress-value {
        background-color: $color;
      }
      &::-moz-progress-bar {
        background-color: $color;
      }
    }
  }
}

.progress-status {
  @include progressStatus("error", $error);
  @include progressStatus("warning", $warning);
  @include progressStatus("success", $success);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  progress {
    -webkit-appearance: none;
    appearance: none;
    flex: 1 1 auto;
    border: 1px solid $shade02;
    width: auto;
    height: 7px;

    &::-webkit-progress-bar {
      border-radius: 7px;
    }

    &::-webkit-progress-value {
      border-radius: 7px;
    }
  }

  & > span {
    flex: 0 1 auto;
    margin-left: $boxSizeMargin;
  }
}
