.password-strength-hint {
  position: relative;

  &__show-icon {
    position: absolute;
    top: 0;
    right: 0;

    button {
      margin: auto;
      height: 100%;
    }
  }

  &__criteria-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__criteria {
    flex: 1 1 auto;
    min-width: 50%;

    &--valid {
      color: $success;
    }
    &--invalid {
      color: $error;
    }
  }
}
