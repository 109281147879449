.searchbar-results {
  padding: $boxSizeMargin;
  width: 100vw;

  &--empty {
    text-align: center;
  }

  &__results {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: -$boxSizeMargin;
  }
  &__element {
    flex: 1;
    padding: $boxSizeMargin;
  }

  @media screen and (min-width: 25rem) {
    &__results {
      flex-direction: row;
    }
    &__element {
      min-width: 20rem;
    }
  }
  @media screen and (min-width: 40rem) {
    padding: $boxSizeMargin * 1.5;
    width: auto;
    min-width: 43.5rem;
    &__element {
      min-width: 25rem;
      flex: 1;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    padding: $boxSizeMargin $boxSizeMargin * 2 $boxSizeMargin * 2;
    min-width: 41rem;
    &--two-columns {
      min-width: 64rem;
    }
    &__results {
      margin-top: -$boxSizeMargin * 2;
    }
    &__element {
      padding: $boxSizeMargin * 2;
    }
  }
}
