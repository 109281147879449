@use "sass:math";

.carousel {
  &__slider {
    position: relative;
  }
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__slide {
    position: relative;
    height: 70vh;
    min-height: 30em;
    display: flex;
    align-items: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 40ch;
  }
  &__title {
    > * {
      margin: 0;
    }
  }
  &__link {
    margin-top: $boxSizeMargin * 2;
    text-transform: uppercase;
    font-weight: $bold;
  }

  &__controls {
    display: none;
  }

  &__nav {
    position: absolute;
    left: $boxSizeMargin * 2;
    right: $boxSizeMargin * 2;
    bottom: $boxSizeMargin * 2;
    z-index: 1;
  }
  &__nav-start {
    display: none;
  }
  &__nav-end {
    display: none;
  }
  &__nav-buttons {
    display: flex;
    justify-content: center;
    margin-left: $boxSizeMargin * 2;
    margin-right: $boxSizeMargin * 2;
  }
  &__button {
    display: block;
    flex: 1;
    background: none;
    color: transparent;
    font-size: 0;
    border: none;
    height: $boxSizeMargin * 2;
    position: relative;
    cursor: pointer;
    outline: none;

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translate(0, -50%);
      content: "";
      height: 2px;
      background: rgba($white, 0.12);
      width: 100%;
    }
    &:focus,
    &:hover {
      &::after {
        height: 4px;
        background: $white;
      }
    }
    &--current {
      &::after {
        background: $white;
        width: 100%;
      }
    }
  }

  &__description {
    margin-top: $boxSizeMargin * 2;
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__slide {
      min-height: 40em;
    }
    &__controls {
      position: absolute;
      z-index: 1;
      bottom: $boxSizeMargin * 4;
      right: $boxSizeMargin;
      display: flex;
      margin-left: math.div(-$boxSizeMargin, 2);
      margin-right: math.div(-$boxSizeMargin, 2);

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        color: $fontColor;
        border: none;
        width: $boxSizeMargin * 4;
        height: $boxSizeMargin * 4;
        margin-left: math.div($boxSizeMargin, 2);
        margin-right: math.div($boxSizeMargin, 2);
        transition: transform 0.15s ease-in-out;

        &:hover,
        &:focus {
          transform: scale(1.05);
        }

        &[disabled] {
          cursor: not-allowed;
          color: $white;
          background: rgba($white, 0.12);
          transform: scale(1);
        }
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 10%;
      left: $boxSizeMargin;
      width: $boxSizeMargin * 2;
      height: 80%;
    }
    &--with-description &__nav {
      height: calc(80% - #{$boxSizeMargin * 8});
    }
    &__nav-start,
    &__nav-end {
      display: block;
      text-align: center;
    }
    &__nav-buttons {
      flex-direction: column;
      margin: $boxSizeMargin * 2 0;
      flex: 1;
    }
    &__button {
      flex: 1;
      height: auto;
      width: $boxSizeMargin * 2;

      &::after {
        top: 0;
        left: 50%;
        transform: translate(-50%);
        width: 2px;
        height: 100%;
      }
      &:focus,
      &:hover {
        &::after {
          width: 4px;
          height: 100%;
        }
      }
    }

    &--with-description &__slide {
      padding-bottom: $boxSizeMargin * 8;
    }

    &__content {
      margin: 0;
      padding-left: $boxSizeMargin * 12;
      padding-right: $boxSizeMargin * 12;
      max-width: 80ch;
      max-width: calc(#{$boxSizeMargin * 24} + 50ch);
    }

    &__description {
      position: relative;
      z-index: 1;
      margin-top: -$boxSizeMargin * 8;
      min-height: $boxSizeMargin * 8;
      pointer-events: none;
      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 9999em;
        right: 50%;
        background: $white;
      }
    }
    &__description-content {
      pointer-events: all;
      position: relative;
      padding: $boxSizeMargin * 2 $boxSizeMargin * 4 $boxSizeMargin * 2 0;
      background: $white;
      margin-right: $boxSizeMargin * 12;
      border-top-right-radius: $boxSizeMargin * 4;
      min-height: $boxSizeMargin * 8;
      display: flex;
      align-items: center;
      justify-content: stretch;
    }
  }
}
