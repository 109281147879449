@use "sass:math";

$miniCartDefaultSpace: 0.5em;
$miniCartDefaultLeftMargin: 0.5em;

.mini-cart {
  position: relative;
  z-index: 11;
  border-left: 1px solid $white;

  &__container {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 100%;
    top: calc(100% + #{math.div($boxSizeMargin, 2)});
    width: 24em;
    min-width: 30ch;
    max-width: 98vw;
    max-width: calc(100vw - #{math.div($boxSizeMargin, 4)});
    max-height: 85vh;
    overflow-y: auto;
    border: 1px solid $shade03;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 8px 15px 0 rgba($shade05, 0.05);
  }
}

@media screen and (min-width: 768px) {
  .mini-cart {
    margin: math.div($boxSizeMargin, 2) math.div($boxSizeMargin, 2)
      math.div($boxSizeMargin, 2) $boxSizeMargin;
    padding: $miniCartDefaultSpace ($miniCartDefaultSpace * 2);
  }
}

@media screen and (min-width: 992px) {
  .mini-cart {
    padding: math.div($boxSizeMargin, 2);
  }
}
