@use "sass:math";

.rating-input {
  display: flex;
  align-items: center;
  margin: 0 math.div(-$boxSizeMargin, 4);

  &__input,
  &__value {
    padding: 0 math.div($boxSizeMargin, 4);
  }

  &__input {
    position: relative;
    display: inline-block;
  }

  &__radio-group {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 math.div($boxSizeMargin, 4);
    margin: -0.1rem;
    display: flex;
    > * {
      width: 20%;
      padding: 0.1rem;
    }
  }

  &__radio {
    position: relative;
    margin: 0;

    > input {
      @extend .sr-only;
    }
    > input:focus + .rating-input__focus-hint::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: -2px;
      left: 0.1rem;
      right: 0.1rem;

      border-bottom: $brandPrimary 2px solid;
    }

    > input,
    > label {
      @extend .sr-only;
    }
  }

  &__value {
    display: flex;
    align-items: center;
  }
}
