.expandable-card {
  border: 1px solid $divider;
  padding: $boxSizeMargin;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -$boxSizeMargin;
    padding: $boxSizeMargin;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  @media screen and (min-width: $menuBreakpoint) {
    padding: $boxSizeMargin * 1.5 $boxSizeMargin * 2;
    &__header {
      padding: $boxSizeMargin * 1.5 $boxSizeMargin * 2;
      margin: (-$boxSizeMargin * 1.5) (-$boxSizeMargin * 2);
    }
  }
  &--expanded &__header {
    margin-bottom: 0;
  }
}
