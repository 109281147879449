.return-item-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__preview {
    margin-right: 1.5rem;
  }
  &__preview-container {
    height: 5rem;
    width: 5rem;
    position: relative;
    cursor: pointer;
  }
  &__details {
    flex: 1;
  }
}
