.product-item {
  position: relative;
  overflow: hidden;

  &__overview {
    margin-top: $boxSizeMargin * 2;
  }

  &__label {
    position: absolute;
    top: $boxSizeMargin;
    left: $boxSizeMargin;
  }

  &__actions {
    position: absolute;
    top: $boxSizeMargin;
    right: $boxSizeMargin;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__label {
      top: $boxSizeMargin * 1.5;
      left: $boxSizeMargin * 1.5;
    }
    &__actions {
      top: $boxSizeMargin * 1.5;
      right: $boxSizeMargin * 1.5;
    }
  }
}
