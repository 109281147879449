.facet {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__label {
    margin-right: 0.3em;
  }
  &__count {
    color: $shade04;
  }
  &__reset {
    &[aria-hidden="true"] {
      visibility: hidden;
    }
    > * {
      margin: 0;
    }
  }
}
