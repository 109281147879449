@use "sass:math";

.cart-item-details {
  display: flex;
  flex-direction: column;

  &__image {
    max-width: 10em;
    min-height: 7em;
    min-width: 0;
    width: 7em;
    padding-bottom: $boxSizeMargin;
    align-self: center;
    color: $shade04;
  }
  &__image-container {
    position: relative;
  }

  &__content {
    flex: 1;
    width: 100%;

    > * {
      padding-top: math.div($boxSizeMargin, 2);
      padding-bottom: math.div($boxSizeMargin, 2);

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    &--no-option {
      > :nth-last-child(2) {
        padding-bottom: 0;
      }
    }
  }

  &__name {
    > * {
      margin: 0;
    }
  }

  @media screen and (min-width: 30em) {
    flex-direction: row;
    align-items: flex-start;

    &__image {
      width: 25%;
      min-width: 5em;
      padding-bottom: 0;
      max-width: 10em;
      padding-top: 0.9rem;
      padding-right: $boxSizeMargin;
      align-self: flex-start;
    }

    &__content {
      padding-left: $boxSizeMargin;
      align-self: center;
    }

    &__name {
      padding-right: $boxSizeMargin * 2;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__image {
      padding-top: 0;
      padding-right: $boxSizeMargin * 2;
      align-self: center;
    }

    &__content {
      padding-left: $boxSizeMargin * 2;
    }
    &__options {
      margin-bottom: math.div(-$boxSizeMargin, 2);
    }
  }
}
