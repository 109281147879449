@use "sass:math";

.postal-address-list {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  &__item {
    padding: $boxSizeMargin 0;
    @media screen and (min-width: 350px) {
      padding: $boxSizeMargin;
    }

    &--active {
      background-color: $shade02;
    }

    &:focus,
    &--focused {
      outline: 2px solid $shade05;
      outline-offset: -2px;
    }

    & + & {
      border-top: 1px solid $shade02;
    }
  }

  &__action {
    margin-top: math.div($boxSizeMargin, 2);
  }
}
