@use "sass:math";

.radio {
  position: relative;
  display: flex;
  align-items: center;

  > label {
    position: relative;
    flex: 1;
    text-align: left;
    padding-left: 2rem + $boxSizeMargin;
    min-height: 2rem;
    padding-top: 0.15rem;
    display: inline-block;
  }

  input[type="radio"] {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  input[type="radio"] + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    text-align: center;
    border: 1px solid $divider;
    border-radius: 50%;
    box-sizing: border-box;
  }

  input[type="radio"]:checked + label:before {
    content: "•";
    background-color: $black;
    color: $white;
    font-size: 3rem;
    line-height: 2rem;
    border: none;
    border-radius: 50%;
  }

  input[type="radio"]:focus + label:after,
  input[type="radio"]:hover + label:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    display: block;
    width: calc(2rem - 2px);
    height: calc(2rem - 2px);
    z-index: 1;

    border: 2px solid $brandPrimary;
    border-radius: 50%;
    box-sizing: border-box;

    // outline: 2px solid $brandPrimary;
    // outline-offset: -3px;
    // border-radius: 50%;
  }
}

.radio + .radio {
  margin-top: math.div($boxSizeMargin, 4);
}
