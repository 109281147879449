@use "sass:math";

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: math.div(-$boxSizeMargin, 2);

  &--gap-0-5 {
    margin: math.div(-$boxSizeMargin, 2);
    .cell {
      padding: math.div($boxSizeMargin, 2);
    }
  }
  &--gap-2 {
    margin: -$boxSizeMargin;
    .cell {
      padding: $boxSizeMargin;
    }
  }
  &--gap-4 {
    margin: -$boxSizeMargin * 2;
    .cell {
      padding: $boxSizeMargin * 2;
    }
  }

  &--vertical {
    flex-direction: column;
  }
}

.cell {
  flex: 1;
  padding: math.div($boxSizeMargin, 2);
}

.cell--1 {
  flex: 1;
}
.cell--2 {
  flex: 2;
}
.cell--3 {
  flex: 3;
}
.cell--desktop-1,
.cell--desktop-2,
.cell--desktop-3 {
  min-width: 100%;
}

.cell--width-1 {
  min-width: 100%;
  max-width: 100%;
}
.cell--width-1-2 {
  min-width: 50%;
  max-width: 50%;
}
.cell--width-1-3 {
  min-width: 33.333%;
  max-width: 33.333%;
}

@media screen and (min-width: $menuBreakpoint) {
  .grid {
    margin: -$boxSizeMargin;

    &--gap-0-5 {
      margin: math.div(-$boxSizeMargin, 2);
      .cell {
        padding: math.div($boxSizeMargin, 2);
      }
    }
    &--gap-2 {
      margin: -$boxSizeMargin;
      .cell {
        padding: $boxSizeMargin;
      }
    }
    &--gap-4 {
      margin: -$boxSizeMargin * 2;
      .cell {
        padding: $boxSizeMargin * 2;
      }
    }
  }

  .cell {
    padding: $boxSizeMargin;
  }

  .cell--desktop-1,
  .cell--desktop-2,
  .cell--desktop-3 {
    min-width: auto;
  }
  .cell--desktop-1 {
    flex: 1;
  }
  .cell--desktop-2 {
    flex: 2;
  }
  .cell--desktop-3 {
    flex: 3;
  }

  .cell--width-desktop-1-2 {
    min-width: 50%;
    max-width: 50%;
  }
  .cell--width-desktop-1-3 {
    min-width: 33.333%;
    max-width: 33.333%;
  }
}

@media print {
  .grid {
    .cell--print-1-4 {
      min-width: 25%;
      max-width: 25%;
    }
  }
}
