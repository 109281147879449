.total {
  margin: auto;
  font-size: $mediumSize;
  &__title {
    padding-right: 2em;
    text-align: right;
  }
  &__price {
    padding-bottom: 0.7em;
    text-align: right;
  }
  &__total {
    font-weight: $bold;
  }
}
