@use "sass:math";

.account-sub-navigation {
  padding-top: $boxSizeMargin * 2;
  padding-bottom: $boxSizeMargin * 2;

  &__list {
    display: block;
    list-style: none;
    margin: math.div(-$boxSizeMargin, 2);
    padding: 0;
  }

  &__item {
    padding: math.div($boxSizeMargin, 2);
  }

  @media screen and (min-height: 40em) {
    &__list {
      margin: math.div(-$boxSizeMargin, 4) * 3;
    }
    &__item {
      padding: math.div($boxSizeMargin, 4) * 3;
    }
  }
}
