@use "sass:math";

.select-product-configuration-modal {
  &__button {
    width: 100%;
    padding: math.div($boxSizeMargin, 2);
    text-align: center;
    & > * {
      width: 100%;
    }
  }
}
