.coupon-code {
  width: 100%;
  &__title {
    font-weight: $semiBold;
    font-size: $mediumSize;
    margin-bottom: 1em;
    text-align: center;
  }
  &__entry {
    margin: 0 auto;
    &__input {
      margin: 0 auto;
    }
    &__validate {
      margin-top: $boxSizeMargin;

      > * {
        width: 100%;
      }
    }
  }
}
