.form-title {
  text-align: center;

  > :first-child {
    margin-top: 0;
  }
  > :first-child {
    margin-bottom: 0;
  }
}
