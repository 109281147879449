.estimation-options {
  display: flex;
  align-items: center;
  margin-bottom: $boxSizeMargin;
  font-size: $smallSize;

  > * {
    width: 100%;
  }
}
