.editorial-toolbox {
  &__header {
    background: $frontCommercePrimary;
    color: $white;
    font-size: 1.2rem;
    padding: calc($boxSizeMargin/2) $boxSizeMargin;
    font-weight: bold;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__content {
    font-size: 10rem;
    text-align: center;
  }

  .switch__interactive input:checked + label {
    background-color: $frontCommercePrimary;
  }
}
