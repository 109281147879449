@use "sass:math";

.add-to-cart {
  &__error {
    outline: none;
    width: 100%;
    > * {
      margin: 0;
    }
  }

  &__main {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: math.div(-$boxSizeMargin, 2);
  }
  &--center &__main {
    justify-content: center;
  }

  &__quantity {
    padding: math.div($boxSizeMargin, 2);
  }

  &__button {
    padding: math.div($boxSizeMargin, 2);

    > * {
      width: 100%;
    }
  }

  &__actions {
    display: none;
    padding: math.div($boxSizeMargin, 2);

    @media screen and (min-width: $menuBreakpoint) {
      display: block;
    }
  }
}
