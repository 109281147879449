@use "sass:math";

.cart-title {
  margin: 0;
  text-align: center;

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    padding: $boxSizeMargin;
    z-index: 1;
    border-top: 1px solid $shade03;
  }

  &__headline {
    > * {
      margin: 0;
    }
  }

  &__error {
    margin-top: math.div($boxSizeMargin, 2);
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .cart-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $boxSizeMargin * 2 0;
    flex-wrap: wrap;

    &__headline {
      text-align: left;
      flex: 1;
    }

    &__actions {
      position: static;
      padding: 0;
      border-top: none;
      z-index: 0;
      width: auto;
      display: block;
      margin-top: 0;
    }

    &__error {
      display: block;
      width: 100%;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
