@use "sass:math";

.cart-infos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  margin: math.div(-$boxSizeMargin, 4);
  font-weight: $bold;

  &__item {
    padding: math.div($boxSizeMargin, 4);
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .cart-infos {
    &__item {
      text-align: center;
      flex: 1;
      width: 33.333%;
      &--quantity {
        flex: 2;
        display: flex;
        justify-content: center;
      }
    }
  }
}
