@use "sass:math";

.tabs {
  &__head {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: -1.5rem;
      width: 1.5rem;
      z-index: 1;
      background: linear-gradient(
        90deg,
        rgba($white, 1) 0%,
        rgba($white, 0.75) 33%,
        rgba($white, 0.4) 67%,
        rgba($white, 0) 100%
      );
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: -1.5rem;
      width: 1.5rem;
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba($white, 1) 0%,
        rgba($white, 0.75) 33%,
        rgba($white, 0.4) 67%,
        rgba($white, 0) 100%
      );
    }
  }
  &__controls {
    text-align: center;
    white-space: nowrap;
    margin: math.div(-$boxSizeMargin, 2) -1.5rem;
    padding-left: 1.5rem - math.div($boxSizeMargin, 2);
    padding-right: 1.5rem - math.div($boxSizeMargin, 2);
    overflow-x: auto;
    max-width: calc(100% + 3rem);

    > * {
      margin: math.div($boxSizeMargin, 2);
    }
  }

  &__content {
    display: none;
    margin-top: $boxSizeMargin * 2;

    &--active {
      display: block;
    }
  }
}
