@use "sass:math";

.review {
  &__main-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 math.div(-$boxSizeMargin, 4);
  }
  &__title,
  &__stars {
    padding: 0 math.div($boxSizeMargin, 4);
  }
  &__title {
    > * {
      margin: 0;
    }
  }
  &__content {
    margin-top: $boxSizeMargin;
  }

  &--closed {
    overflow: hidden;
    .review__content {
      position: relative;
      max-height: 8rem;
    }

    .review__toggle {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      height: 6rem;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 33%,
        rgba(255, 255, 255, 0.75) 55%,
        rgba(255, 255, 255, 1) 75%
      );
      padding-top: 3rem;
      text-align: center;
    }
  }
}
