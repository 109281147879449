.layer-products {
  display: flex;
  flex-wrap: wrap;
  margin: -$boxSizeMargin;
  &__element {
    width: 50%;
    padding: $boxSizeMargin;
  }

  @media screen and (min-width: 70em) {
    &__element {
      width: 33.33%;
    }
  }
}
