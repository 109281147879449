@use "sass:math";

.downloadable-product-item {
  &,
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__header {
    font-weight: bold;
    border-bottom: 2px solid $shade05;
  }
  &__order {
    flex: 0 0 8em;
    max-width: 8em;
    min-width: 0;
    width: 8em;
  }
  &__date {
    flex: 0 0 8em;
    max-width: 8em;
    min-width: 0;
    width: 8em;
  }
  &__title {
    padding: 0 $boxSizeMargin;
    flex: 1 1 22em;
    min-width: 22em;
  }
  &__status {
    flex: 0 0 6em;
    max-width: 6em;
    min-width: 0;
    width: 6em;
  }
  &__remaining-downloads {
    padding: 0 $boxSizeMargin;
    flex: 0 0 12em;
    max-width: 12em;
    min-width: 0;
    width: 12em;
  }
}
