@use "sass:math";

.cart-content {
  margin: math.div(-$boxSizeMargin, 2) 0;
  @media screen and (min-width: $menuBreakpoint) {
    display: table;
    width: 100%;
    margin: math.div(-$boxSizeMargin, 2) 0 (-$boxSizeMargin);

    &__list {
      display: table-row-group;
    }
  }
}
