@use "sass:math";

.active-facets {
  display: flex;
  flex-wrap: wrap;
  margin: math.div(-$boxSizeMargin, 2);

  &__element {
    padding: math.div($boxSizeMargin, 2);
  }
}
