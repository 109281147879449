@use "sass:math";

.order-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: math.div(-$boxSizeMargin, 4) math.div(-$boxSizeMargin, 2);

  &__action {
    padding: math.div($boxSizeMargin, 4) math.div($boxSizeMargin, 2);
  }
}
