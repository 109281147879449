@use "sass:math";

.product-name {
  &__main {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    flex: 3;
  }
  &__id {
    display: none;
    align-self: flex-start;
    min-height: 2.8rem;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    min-width: 10em;
    text-align: right;

    @media screen and (min-width: $menuBreakpoint) {
      display: flex;
      padding-left: $boxSizeMargin;
    }
    @media print {
      display: flex;
      padding-left: $boxSizeMargin;
    }
    .product-name--with-labels & {
      height: auto;
      padding-top: math.div($boxSizeMargin, 2);
    }
  }

  &__labels {
    margin-bottom: $boxSizeMargin;
  }
  &__name {
    > * {
      margin: 0;
    }
  }

  &__price {
    font-size: $veryLargeSize;
    > * {
      margin: 0;
    }
  }
}
