@import "~./ResetPayzenForm";

.payzen-form {
  &--loading {
    opacity: 0.1;
  }
}
.payzen-form-input {
  @extend input;
  height: 34px;
  display: flex;
  align-items: center;
}
.kr-form-error {
  display: none;
}
.kr-payment-button {
  display: none !important;
}
