.paypal-button {
  &__button {
    position: relative;
    margin: 0 auto;
    text-align: center;

    > * {
      position: relative;
      z-index: 0;
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    display: block;
    width: 100%;
    background: none;
    border: 0;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
}
