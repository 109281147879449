.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.block {
  display: block;
}
.h-6 {
  height: 1.5rem;
}
.h-32 {
  height: 8rem;
}
.h-10 {
  height: 2.5rem;
}
.h-60 {
  height: 15rem;
}
.w-40 {
  width: 10rem;
}
.w-full {
  width: 100%;
}
.w-\[60\%\] {
  width: 60%;
}
.w-\[40\%\] {
  width: 40%;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.border-b {
  border-bottom-width: 1px;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-lg,
.text-xl {
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.font-extrabold {
  font-weight: 800;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.opacity-75 {
  opacity: 0.75;
}
.hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}
.group:hover .group-hover\:text-\[\#BB1F4E\] {
  --tw-text-opacity: 1;
  color: rgb(187 31 78 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .sm\:mt-4 {
    margin-top: 1rem;
  }
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
