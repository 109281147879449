.category-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $boxSizeMargin;

  &__image {
    position: relative;
    margin-bottom: $boxSizeMargin;
  }
}

@media screen and (min-width: 768px) {
  .category-description {
    flex-direction: row;
    padding: $boxSizeMargin 0;

    &__image {
      margin-bottom: 0;
      margin-right: $boxSizeMargin * 2;

      > * {
        max-height: 10em;
        width: 12em;
      }
    }
  }
}
