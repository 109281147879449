.editable-address {
  &__address {
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
  &__button {
    margin-top: $boxSizeMargin;
    margin-bottom: -0.5rem;
    position: relative;
    z-index: 1;
    font-weight: $bold;
  }
}
