/**
 * Styles are mostly duplicated from
 * theme/components/atoms/Form/Input/_Input.scss
 * while we decide about the best way to reuse and share code between
 * these atoms
 */
@use "sass:math";

.StripeElement {
  border: 1px solid $inputBorderColor;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  font-size: $regularSize;
  font-family: $fontFamily;
  width: 100%;

  padding: math.div($boxSizeMargin, 2);
}

.StripeElement--invalid {
  border: 1px solid $red;
}

.StripeElement--webkit-autofill {
  background-color: $shade03 !important;
}
