@use "sass:math";

.product-price {
  &__prefix {
    font-size: $regularSize;
  }
  &__prefix + &__regular {
    &::before {
      content: " ";
    }
  }
  &--special {
    .product-price__price {
      display: flex;
      flex-wrap: wrap;
      > * {
        vertical-align: bottom;
      }
    }
    .product-price__prefix {
      margin-right: math.div($boxSizeMargin, 4);
    }
    .product-price__regular {
      text-decoration: line-through;
    }
    .product-price__special {
      margin-right: $boxSizeMargin;
      color: $red;
    }
    .product-price__regular {
      color: $shade03;
    }
  }
}
