@use "sass:math";

.spacer {
  margin-top: math.div($boxSizeMargin, 2);
  margin-bottom: math.div($boxSizeMargin, 2);
  border: none;

  border-top: 1px solid transparent;
  &--border {
    border-top: 1px solid $divider;
  }

  &--size-2 {
    margin-top: $boxSizeMargin;
    margin-bottom: $boxSizeMargin;
  }
  &--size-4 {
    margin-top: $boxSizeMargin * 2;
    margin-bottom: $boxSizeMargin * 2;
  }

  @media screen and (min-width: $menuBreakpoint) {
    margin-top: $boxSizeMargin;
    margin-bottom: $boxSizeMargin;

    &--size-2 {
      margin-top: $boxSizeMargin * 2;
      margin-bottom: $boxSizeMargin * 2;
    }
    &--size-4 {
      margin-top: $boxSizeMargin * 4;
      margin-bottom: $boxSizeMargin * 4;
    }
  }
}
