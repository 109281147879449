.account-navigation {
  padding-left: 0;
  margin: 0;

  &__item {
    position: relative;
    color: $fontColor;
    padding: 0.7rem 0;

    &:disabled,
    &--disabled {
      pointer-events: none;
      color: $shade03;
    }

    &--active,
    &:hover,
    &:focus {
      font-weight: $bold;
    }
  }
}
