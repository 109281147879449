@use "sass:math";

.cart-footer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    order: 1;
    margin-bottom: $boxSizeMargin;
  }

  &__totals {
    order: 0;
    text-align: center;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .cart-footer {
    width: auto;
    max-width: none;
    flex-direction: row;
    align-items: flex-start;
    margin-left: math.div(-$boxSizeMargin, 2);
    margin-right: math.div(-$boxSizeMargin, 2);

    > * {
      flex: 1;
      margin-left: math.div($boxSizeMargin, 2);
      margin-right: math.div($boxSizeMargin, 2);
    }

    > * + * {
      margin-top: 0;
    }

    &__totals {
      order: 1;
      text-align: right;
    }

    &__validation {
      > * {
        width: 100%;
      }
    }
  }
}
