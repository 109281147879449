.product-reviews {
  padding: 0 $boxSizeMargin;

  &__pagination {
    margin-top: $boxSizeMargin;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .product-reviews {
    padding: 0;

    &__list {
      margin-top: $boxSizeMargin * 2;
      margin-bottom: $boxSizeMargin * 2;
      display: flex;
      justify-content: center;
      margin-left: -$boxSizeMargin;
      margin-right: -$boxSizeMargin;
    }
    &__col {
      width: 50%;
      padding-left: $boxSizeMargin;
      padding-right: $boxSizeMargin;
    }
    &__pagination {
      margin-top: $boxSizeMargin * 2;
      margin-bottom: $boxSizeMargin * 2;
    }
  }
}
