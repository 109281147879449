.multi-step-recap {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: $boxSizeMargin;

  &__details {
    display: flex;
    min-width: 0;
    min-height: 0;
    flex-direction: column;
    justify-content: center;
  }
}
