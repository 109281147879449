@use "sass:math";

.swatches {
  display: flex;
  flex-wrap: wrap;
  margin: math.div(-$boxSizeMargin, 2);

  &__value {
    position: relative;
    margin: math.div($boxSizeMargin, 2);
    width: 40px;
    height: 40px;
    background: $white;
    border: 1px solid $shade04;
    border-radius: 50%;
    padding: 3px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &--selected {
      border: 2px solid $brandPrimary;
    }
    &:focus {
      outline: none;
      border: 3px solid $shade04;
    }
    &--disabled {
      border: 1px solid $shade04;
      background-color: rgba($shade04, 0.3);
      cursor: not-allowed;
      opacity: 0.4;

      &:focus,
      &:hover {
        border: 1px solid $shade04;
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  &__image,
  &__color {
    display: block;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  &__image {
    position: relative;
  }
}
