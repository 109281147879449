.kr-embedded {
  display: block;
}
.kr-embedded .kr-pan,
.kr-embedded .kr-expiry,
.kr-embedded .kr-security-code,
.kr-embedded .kr-installment-number,
.kr-embedded .kr-identity-document-type,
.kr-embedded .kr-identity-document-number,
.kr-embedded .kr-card-holder-name,
.kr-embedded .kr-card-holder-mail,
.kr-embedded div[id^="kr-field-app-"] {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.kr-embedded .kr-form-error,
.kr-embedded .kr-payment-button {
  -webkit-box-ordinal-group: 31;
  -webkit-order: 30;
  -moz-order: 30;
  -ms-flex-order: 30;
  order: 30;
}

.kr-embedded.kr-out-of-view-form[krvue],
.kr-embedded.kr-out-of-view-form[krvue][kr-popin] {
  position: fixed;
  top: -4000px;
  left: -4000px;
  right: -4000px;
  bottom: -4000px;
}

.kr-embedded[krvue] .kr-expiry.kr-size-half,
.kr-embedded[krvue] .kr-security-code.kr-size-half {
  width: calc(50% - 5px);
}

/* Single line CSS */
.kr-embedded.kr-single-line-form:before,
.kr-embedded.kr-single-line-form:after {
  content: " ";
  display: table;
}

.kr-embedded.kr-single-line-form:after {
  clear: both;
}

.kr-embedded.kr-single-line-form .kr-single-line-field,
.kr-embedded.kr-single-line-form .kr-field-wrapper {
  display: inline-block;
  float: left;
}

/* Split payment CSS */
.kr-split-payment-status-wrapper {
  display: inline-block;
}

.kr-split-payment-status-wrapper span svg {
  height: auto;
  display: inline-block;
  margin: 0 2px;
  width: 17px;
}

@-webkit-keyframes krload {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes krload {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.kr-split-loader,
.kr-split-loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.kr-split-loader {
  margin: 1px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid rgba(154, 154, 154, 0.3);
  border-right: 0.4em solid rgba(154, 154, 154, 0.3);
  border-bottom: 0.4em solid rgba(154, 154, 154, 0.3);
  border-left: 0.4em solid #787878;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: krload 1.1s infinite linear;
  animation: krload 1.1s infinite linear;
}

.kr-embedded .kr-icon-wrapper-root {
  display: none;
}
.kr-embedded .kr-icon-wrapper-root.kr-booted {
  display: block;
}

/* Grid CSS */
.kr-block-group,
.kr-block,
.kr-block-group:after,
.kr-block:after,
.kr-block-group:before,
.kr-block:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.kr-block-group:before,
.kr-block-group:after {
  display: table;
  content: "";
  line-height: 0;
}

.kr-block-group:after {
  clear: both;
}

.kr-block-group {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.kr-block-group > .kr-block-group {
  clear: none;
  float: left;
  margin: 0 !important;
}

.kr-block {
  float: left;
  width: 100%;
}

.kr-selectors {
  margin-left: 10px;
}

.kr-selectors > div {
  padding: 6px;
  display: inline-block;
}

.kr-selectors img {
  width: 100%;
}

/* Popin CSS */
.kr-popin-utils .kr-popin-background {
  background-color: #232323;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
}

.kr-popin-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  left: -9999px;
  position: fixed;
  top: -9999px;
  z-index: 9999;
}
.kr-popin-wrapper .kr-embedded[kr-popin] {
  display: flex;
  flex-direction: column;
}
@media (max-width: 410px) {
  .kr-popin-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: start;
    -moz-align-items: start;
    align-items: start;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 800px) and (max-height: 500px) {
  .kr-popin-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: start;
    -moz-align-items: start;
    align-items: start;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 350px) {
  .kr-popin-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.kr-embedded[kr-popin] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-bottom: 80px;
  padding-top: 119px;
  position: relative;
}
.kr-embedded[kr-popin] .kr-popin-modal-header {
  background-color: #e4e4e4;
  color: #333333;
  height: 90px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.kr-embedded[kr-popin] .kr-popin-modal-header .kr-popin-modal-header-background,
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.kr-embedded[kr-popin] .kr-popin-modal-header i.fi-lock svg {
  height: 20px;
  left: 0;
  margin-left: 9px;
  margin-top: 5px;
  position: absolute;
  top: 0;
  width: 19px;
}
.kr-embedded[kr-popin] .kr-popin-modal-header h3.kr-popin-header {
  font-size: 12px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  display: block;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  left: 0;
  top: 0;
}
.kr-embedded[kr-popin] .kr-popin-modal-header .kr-popin-shop-name {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  margin-top: 50px;
  padding: 0 20px;
  /* white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; */
  left: 0;
  top: 0;
  position: relative;
}
.kr-embedded[kr-popin] .kr-popin-modal-header .kr-popin-shop-name span {
  font-size: 16px;
}
.kr-embedded[kr-popin] .kr-popin-modal-header i.fi-x-circle svg {
  cursor: pointer;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  z-index: 5;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer {
  background-color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer img {
  margin-right: 30px;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer img.kr-logo-payzen {
  height: 40px;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer img.kr-logo-lyra {
  height: 40px;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer img.kr-logo-sogecom {
  height: 20px;
}
.kr-embedded[kr-popin] .kr-popin-modal-footer img.kr-logo-cdn {
  height: 30px;
}
.kr-embedded[kr-popin][krvue].kr-secure-iframe-open {
  padding: 30px 0 0 0;
  width: auto;
  height: auto;
}

/* 3ds mobile */
@media (max-width: 450px) {
  .kr-embedded.kr-secure-iframe-open[kr-popin] {
    bottom: 0;
    left: 0;
    margin-top: 0px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .kr-embedded.kr-secure-iframe-open[kr-popin]
    .kr-secure-iframe-visible.kr-popin-modal-header {
    height: 40px;
  }
  .kr-embedded.kr-secure-iframe-open[kr-popin]
    .kr-secure-iframe-visible.kr-popin-modal-header
    i.fi-lock
    svg {
    margin-top: 10px;
  }
  .kr-embedded.kr-secure-iframe-open[kr-popin]
    .kr-secure-iframe-visible.kr-popin-modal-header
    i.fi-x-circle
    svg {
    margin-right: 9px;
    margin-top: 10px;
  }
  .kr-embedded.kr-secure-iframe-open[kr-popin] iframe#krcheckout {
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    position: fixed !important;
    right: 0 !important;
    top: 40px !important;
    width: 100% !important;
  }
}

/* Header logo */
.kr-embedded[kr-popin] .kr-popin-modal-header .kr-popin-modal-header-image {
  position: absolute;
  top: -44px;
  width: 84px;
  height: 84px;
}
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  img.kr-header-logo,
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  .kr-header-border-shadow,
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  .kr-header-border {
  border-radius: 50%;
  position: absolute;
}
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  .kr-header-border-shadow {
  width: 84px;
  height: 84px;
  background-color: rgba(0, 0, 0, 0.2);
}
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  .kr-header-border {
  width: 80px;
  height: 80px;
  background-color: #808080;
  margin-top: 2px;
  margin-left: 2px;
}
.kr-embedded[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image
  img.kr-header-logo {
  width: 74px;
  height: 74px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: #222;
}

/* Internet Explorer 11 */
.kr-embedded.kr-internet-explorer[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image {
  margin-left: 42px;
}

.kr-embedded.kr-internet-explorer[kr-popin]
  .kr-popin-modal-footer
  .kr-whitelabel-logo
  img {
  position: absolute;
  right: 0;
  bottom: 22px;
}

/* iOS10 */
.kr-embedded.kr-ios-10[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image,
.kr-embedded.kr-ios-9[kr-popin]
  .kr-popin-modal-header
  .kr-popin-modal-header-image {
  margin-left: 70px;
}

.kr-brand-buttons,
.kr-embedded .kr-brand-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  min-height: 40px;
}
.kr-brand-buttons .kr-brand-button,
.kr-embedded .kr-brand-buttons .kr-brand-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.kr-brand-buttons .kr-brand-button .kr-brand-button-icon,
.kr-embedded .kr-brand-buttons .kr-brand-button .kr-brand-button-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 40px;
  width: 50px;
  position: relative;
}
.kr-brand-buttons .kr-brand-button .kr-brand-button-icon svg,
.kr-embedded .kr-brand-buttons .kr-brand-button .kr-brand-button-icon svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.kr-brand-buttons .kr-brand-button .kr-brand-button-label,
.kr-embedded .kr-brand-buttons .kr-brand-button .kr-brand-button-label {
  text-align: center;
  text-transform: lowercase;
}

.kr-embedded {
  /* Help */
  /* Help modal */
  /* Payment button CSS */
  /* Reset CSS */
  /* Form */
}
.kr-embedded .kr-icon {
  position: relative;
}
.kr-embedded .kr-icon .kr-icon-wrapper {
  display: none;
}
.kr-embedded .kr-icon-arrow {
  display: none;
}
.kr-embedded .kr-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
}
.kr-embedded .kr-field-wrapper .kr-field-component {
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.kr-embedded .kr-field-wrapper .kr-icon-wrapper-root {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -moz-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.kr-embedded .kr-field-wrapper .kr-help-button-wrapper {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -moz-order: 4;
  -ms-flex-order: 4;
  order: 4;
}
.kr-embedded .kr-field-wrapper .kr-help-button-wrapper {
  display: none;
}
.kr-embedded .kr-field-wrapper .kr-help-button-wrapper.kr-inner {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -moz-order: 4;
  -ms-flex-order: 4;
  order: 4;
  display: none;
}
.kr-embedded .kr-field-wrapper .kr-help-button-wrapper.kr-inner.kr-visible {
  display: block;
}
.kr-embedded
  .kr-field-wrapper
  .kr-help-button-wrapper.kr-inner.kr-visible
  .kr-help-button {
  display: none;
}
.kr-embedded .kr-help-button-wrapper .kr-help-modal-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal-background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0.7;
  background-color: #232323;
}
.kr-embedded .kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 300px;
  padding: 20px;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-header {
  display: block;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-header
  .kr-help-modal-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  cursor: pointer;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content {
  display: block;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  > div.kr-help-content,
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  > div {
  display: block;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  > div.kr-help-content {
  margin: 10px 0;
  font-size: 14px;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  svg,
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  svg
  image {
  width: 100%;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  svg {
  height: 106px;
}
.kr-embedded
  .kr-help-button-wrapper
  .kr-help-modal-wrapper
  .kr-help-modal
  .kr-help-modal-content
  svg
  image {
  height: 100%;
}
.kr-embedded .kr-select-wrapper.kr-real-select select {
  width: 100%;
  background: #fff;
  border: 1px solid #000;
}
.kr-embedded .kr-select-wrapper.kr-real-select .kr-select-caret {
  display: none;
}
.kr-embedded .kr-select-wrapper.kr-real-select .kr-select-caret svg {
  width: 8px;
  height: 8px;
}
.kr-embedded .kr-select-wrapper.kr-custom-select {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.kr-embedded .kr-select-wrapper.kr-custom-select .kr-select {
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  border: 1px solid #000;
  position: relative;
  cursor: default;
}
.kr-embedded .kr-select-wrapper.kr-custom-select .kr-select .kr-select-caret {
  position: absolute;
  right: 5px;
}
.kr-embedded
  .kr-select-wrapper.kr-custom-select
  .kr-select
  .kr-select-caret
  svg {
  width: 8px;
  height: 8px;
}
.kr-embedded .kr-select-wrapper.kr-custom-select .kr-options {
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  position: absolute;
  width: 100%;
  top: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: default;
}
.kr-embedded .kr-select-wrapper.kr-custom-select .kr-options .kr-option {
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  cursor: default;
}
.kr-embedded .kr-select-wrapper.kr-custom-select .kr-options .kr-option:hover {
  background-color: #ccc;
}
.kr-embedded .kr-error-animation.kr-select-wrapper select,
.kr-embedded
  .kr-error-animation.kr-select-wrapper
  .kr-select
  span.kr-selected-option,
.kr-embedded .kr-error-animation.kr-text-wrapper input {
  animation: shake-anim 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake-anim {
  10%,
  90% {
    -moz-transform: translate3d(-1px, 0, 0);
    -o-transform: translate3d(-1px, 0, 0);
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -moz-transform: translate3d(2px, 0, 0);
    -o-transform: translate3d(2px, 0, 0);
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -moz-transform: translate3d(-4px, 0, 0);
    -o-transform: translate3d(-4px, 0, 0);
    -ms-transform: translate3d(-4px, 0, 0);
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -moz-transform: translate3d(4px, 0, 0);
    -o-transform: translate3d(4px, 0, 0);
    -ms-transform: translate3d(4px, 0, 0);
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
.kr-embedded button::-moz-focus-inner {
  border: 0;
}
.kr-embedded input.kr-input-field {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  box-shadow: initial;
  transition: initial;
  height: 1.05em;
  width: 230px;
  line-height: 1em;
  font-size: 1em;
}
.kr-embedded #krFieldRepresentation,
.kr-embedded #krFieldRepresentationError,
.kr-embedded #krFieldRepresentationDisabled {
  position: fixed;
  top: -10000px;
  left: -10000px;
}
