@use "sass:math";

.large-product-synthesis {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item {
    margin-bottom: math.div($boxSizeMargin, 2);
    &:first-child {
      margin-bottom: 0;
    }
  }
}
