@use "sass:math";

.inline-alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &--with-icon > :first-child {
    margin-right: 0.3rem;
    @media screen and (min-width: $tabletBreakpoint) {
      margin-right: math.div($boxSizeMargin, 2);
    }
  }
  &--success {
    color: $success;
  }
  &--error {
    color: $warning;
  }
}
