@use "sass:math";

.option-picker {
  margin: math.div(-$boxSizeMargin, 2) 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  &--checkbox {
    flex-direction: column;
    align-items: flex-start;
  }

  &::after {
    content: "";
    flex: 15;
  }

  &__element {
    padding: math.div($boxSizeMargin, 2) 0;
    margin-right: -1px;
    flex: 1;
  }

  &__element--disabled {
    cursor: not-allowed;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 5.5ch;
    height: 100%;
    background-color: $optionItemBackground;
    border: 1px solid $divider;
    color: $optionItemColor;
    padding: math.div($boxSizeMargin, 2);
    text-align: center;
    box-sizing: border-box;
    font-size: inherit;
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;

    &--disabled {
      border-color: $shade03;
      border-width: 1px;
      color: $shade03;
    }

    &--selected,
    &--clickable:hover:not(&--disabled),
    &--clickable:focus:not(&--disabled) {
      position: relative;
      z-index: 1;
      border: 1px solid $fontColor;
      cursor: pointer;
    }
    &--clickable:hover:not(&--disabled),
    &--clickable:focus:not(&--disabled) {
      background-color: $shade02;
    }
    &--selected,
    &--selected:hover:not(&--disabled),
    &--selected:focus:not(&--disabled) {
      font-weight: bold;
      border-width: 2px;

      padding: calc(#{math.div($boxSizeMargin, 2)} - 1px);
    }
  }

  &__swatch-color {
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  &__count {
    color: $shade04;
  }

  &__element--disabled &__count {
    color: $shade03;
  }

  @media screen and (min-width: $menuBreakpoint) {
    margin: math.div(-$boxSizeMargin, 2) 0;
    &__element {
      padding: math.div($boxSizeMargin, 2) 0;
    }
  }
}
