@use "sass:math";

.delivery-estimation {
  width: 100%;
  &__title {
    font-weight: $semiBold;
    font-size: $mediumSize;
    margin-bottom: 1em;
    text-align: center;
  }
  &__validate {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &__results {
    margin-top: $boxSizeMargin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__item {
      border-bottom: solid 1px $divider;
      margin-top: math.div($boxSizeMargin, 2);
      padding-bottom: math.div($boxSizeMargin, 2);

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}
