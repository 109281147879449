.autocomplete-item {
  display: flex;
  align-items: center;

  &__image {
    margin-right: $boxSizeMargin;
    flex: 0 0 15%;
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0.5em;
    max-width: 80%;
    flex: 1;
    font-weight: $bold;
  }

  &__addon {
    font-weight: normal;
  }
}
