@use "sass:math";

.bundle-option {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: math.div(-$boxSizeMargin, 2);
  margin-right: math.div(-$boxSizeMargin, 2);

  > * {
    padding: 0 math.div($boxSizeMargin, 2);
  }

  @media screen and (min-width: $menuBreakpoint) {
    margin-left: -$boxSizeMargin;
    margin-right: -$boxSizeMargin;

    > * {
      padding: 0 $boxSizeMargin;
    }
  }

  &__option {
    min-width: 20ch;
    flex: 1;
    > * {
      width: 100%;
    }
  }
}
