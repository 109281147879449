.cart-quantity-form {
  display: flex;
  align-items: center;

  &__button {
    display: none;
  }

  &--small {
    min-width: 7em;
  }
}
