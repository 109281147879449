@use "sass:math";

.radio-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: math.div(-$boxSizeMargin, 2);

  &__element {
    position: relative;
    margin: math.div($boxSizeMargin, 2);
    border: 1px solid $shade04;
    padding: $boxSizeMargin;
    border-radius: $boxSizeMargin * 1.5;
    flex: 1;
    min-width: 20em;
    max-width: 20em;

    transition: background-color 0.1s ease-in-out, border-color 0.2s ease-in-out,
      opacity 0.1s ease-in-out;

    &:hover,
    &:focus {
      background: rgba($shade02, 0.3);
    }

    input[type="radio"] {
      position: absolute;
      overflow: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      margin-right: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &--hasValue &__element {
    border-color: $shade03;
    opacity: 0.6;
    &--checked {
      opacity: 1;
      border-color: $brandPrimary;
    }
  }

  &--small &__element {
    min-width: 9em;
    max-width: 9em;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
