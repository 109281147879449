.quickorder {
  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    gap: $boxSizeMargin;
  }
  .number-input {
    width: 100%;
    margin: auto;

    .input-number {
      width: 100%;
    }
  }

  .input-wrapper__error {
    display: none;
  }

  .autocomplete {
    width: 100%;
  }
}
