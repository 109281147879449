@use "sass:math";

.recap-card {
  &__title {
    margin: 0;
    padding: 0 0 $boxSizeMargin 0;
    display: flex;
    justify-content: flex-start;

    > :first-child {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__actions {
    margin: 0 - (math.div($boxSizeMargin, 2));
    > * {
      margin: 0 math.div($boxSizeMargin, 2);
    }
  }

  &__description {
    text-align: center;
    margin-bottom: $boxSizeMargin;
  }

  &__children {
    padding-bottom: 0;

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__title {
      padding-bottom: $boxSizeMargin * 2;
    }
  }
}
