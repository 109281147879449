fieldset {
  border: none;
  font-size: $regularSize;
  max-width: 25em;
  margin: 0 auto;
  padding: 0;
}
.fieldset--large {
  max-width: 30em;
  @media screen and (min-width: $menuBreakpoint) {
    max-width: 40em;
  }
}

.fieldset--start {
  margin: 0;
}
