.search-box {
  position: relative;
  padding-top: $boxSizeMargin;
  padding-bottom: $boxSizeMargin;

  @media screen and (min-width: $menuBreakpoint) {
    &__form {
      display: flex;
      justify-content: center;
    }
  }
}

@import "~./SearchBarResults/SearchBarResults";
@import "~./CategoriesResults/CategoriesResults";
