@use "sass:math";

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: (-$boxSizeMargin) (-$boxSizeMargin * 1.5);

  &__element {
    display: block;
    flex: 1;
    padding: $boxSizeMargin $boxSizeMargin * 1.5;
    min-width: 100%;
    @media screen and (min-width: 40ch) {
      min-width: 30ch;
    }
  }

  &--center {
    .feature-list__element {
      @media screen and (min-width: 55ch) {
        min-width: 40ch;
      }
    }
    @media screen and (min-width: $menuBreakpoint) {
      justify-content: center;

      .feature-list__element {
        &:first-child:last-child {
          max-width: 60ch;
        }
      }
    }
  }
}

.feature {
  display: flex;

  &__icon {
    color: $brandPrimary;
    max-width: $boxSizeMargin * 3;
  }

  &__content {
    margin-left: $boxSizeMargin * 1.5;
  }

  &__title {
    font-weight: $bold;
    font-size: $mediumSize;
    margin-bottom: math.div($boxSizeMargin, 2);
  }

  &__description {
    color: $shade04;
  }

  &--big,
  &--big-primary,
  &--mega {
    flex-direction: column;
    align-items: stretch;

    .feature__content {
      margin-top: $boxSizeMargin * 2;
      margin-left: 0;
    }

    .feature__title {
      font-size: $largeSize;
    }

    .feature__description {
      margin-top: $boxSizeMargin;
    }
  }
  &--big {
    .feature__icon {
      color: $fontColor;
    }
  }

  &--mega {
    .feature__title {
      text-align: center;
    }

    .feature__icon {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      color: $shade03;
    }

    .feature__description {
      color: $fontColor;
    }
  }
}
