.quick-order-page {
  &__title {
    flex-grow: 1;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
  &__entries {
    display: flex;
    flex-direction: column;
    gap: $boxSizeMargin;

    &--loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: $boxSizeMargin;
  }
  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $boxSizeMargin;

    &-content {
      flex-grow: 1;
    }
  }
  &__download {
    display: none;
  }
}
