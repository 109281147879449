@use "sass:math";

.invoice-summary {
  width: 100%;
  border-collapse: collapse;

  &__header {
    border-bottom: 2px solid $shade05;
  }

  &__number {
    text-align: center;
  }

  &__item {
    border-bottom: 1px solid $shade03;
  }

  &__item-name {
    padding: $boxSizeMargin 0;
  }

  &__totals {
    td {
      padding: math.div($boxSizeMargin, 4) 0;
    }
    tr:first-child td {
      padding-top: $boxSizeMargin;
    }
  }

  &__label {
    padding-right: $boxSizeMargin;
    text-align: right;
  }

  &__grandTotal {
    font-size: $mediumSize;
    font-weight: bold;
  }
}

@media print {
  .invoice-summary {
    td:last-of-type,
    th:last-of-type {
      text-align: right;
    }
  }
}
