.magic-button {
  z-index: 9999;
  position: fixed;
  right: 10px;
  bottom: 10px;
  text-align: right;

  &__button-wrapper {
    border-radius: 50%;
    padding: 5px;
    background-color: $white;
    border: 1px solid $frontCommercePrimary;
    display: inline-block;
  }

  &__button {
    background-color: $frontCommercePrimary;
    color: $white;
    border: 5px solid $frontCommercePrimary;
    padding: 0;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
  }

  &__toolbox {
    position: relative;
    background: $white;
    border: 1px solid $shade03;
    text-align: left;
    margin-bottom: 5px;
    margin-left: auto;
    width: 400px;
    max-width: 80%;
  }
}
