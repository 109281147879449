@use "sass:math";

.grouped-items {
  &__item {
    padding-top: math.div($boxSizeMargin, 2);
    padding-bottom: math.div($boxSizeMargin, 2);

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
