@use "sass:math";

.postal-address-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2em;
  margin: math.div(-$boxSizeMargin, 2);

  &__locations {
    flex: 1;
    height: 350px;
    max-height: 80vh;
    max-width: 100%;
    overflow-y: scroll;
    padding: 0;
    margin: math.div($boxSizeMargin, 2);
  }

  &__map {
    flex: 1;
    height: 350px;
    max-width: 100%;
    margin: math.div($boxSizeMargin, 2);
    z-index: 0;
  }

  @media screen and (min-width: 350px) {
    flex-direction: row;
    &__locations {
      min-width: 350px;
    }

    &__map {
      min-width: 350px;
    }
  }
}
