.section {
  &__title {
    flex: 1;
    text-align: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .section__head {
    display: flex;
    margin-bottom: $boxSizeMargin * 2;
  }
  &--with-actions {
    .section__head {
      align-items: center;
    }
    .section__title {
      flex: 1;
      text-align: left;
      margin-right: $boxSizeMargin;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    .section__head {
      margin-bottom: $boxSizeMargin * 4;
    }
    &--with-actions {
      .section__title {
        margin-right: $boxSizeMargin * 2;
      }
    }
  }
}
