.mini-cart-total {
  text-align: center;
  font-size: $mediumSize;

  &__main {
    font-size: $veryLargeSize;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__link {
    font-weight: bold;
  }
  @media screen and (min-width: $menuBreakpoint) {
    font-size: $largeSize;
    &__main {
      font-size: $extraLargeSize;
    }
  }
}
