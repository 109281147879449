.product-thumb {
  display: flex;
  align-items: center;
  width: 100%;
  margin: $boxSizeMargin 0;

  &__image {
    max-width: 5em;
    min-height: 5em;
    min-width: 5em;
    width: 5em;
    align-self: center;
    color: $shade04;
    padding-right: $boxSizeMargin;

    display: flex;
    align-items: center;
  }
  &__image-container {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__details {
    padding-left: $boxSizeMargin;
  }

  &__name {
    > * {
      margin: 0;
    }
  }
}
