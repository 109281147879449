.configurator {
  min-width: 10em;
  text-align: left;

  &__select {
    max-width: 15em;

    &--full {
      max-width: none;
    }
  }
  &__select > * {
    margin-bottom: $boxSizeMargin;
  }
}

@import "~./BundleOption/BundleOption";
@import "~./OptionPicker/Swatches";
