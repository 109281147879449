.product-added {
  &__image {
    text-align: center;

    > * {
      margin: 0 auto;
    }
  }
  &__details {
    text-align: center;
    margin-top: $boxSizeMargin;
  }
  &__title > * {
    margin: 0;
  }
  &__price {
    font-size: $extraLargeSize;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .product-added {
    &__content {
      display: flex;
      align-items: center;
      align-content: center;
    }

    &__details {
      flex: 1;
      text-align: left;
      margin-top: 0;
    }
    &__image {
      margin-right: $boxSizeMargin * 2;
    }
  }
}
