.icon-with-notification {
  position: relative;
  display: block;

  > :first-child {
    display: block;
  }

  &__notification {
    font-size: $smallSize;
    position: absolute;
    top: -0.5em;
    right: -0.85em;
    color: $fontColor;
    background: $brandPrimary;
    line-height: 1em;
    min-width: 1.6em;
    min-height: 1.6em;
    padding: 0.32em 0.3em 0.28em;
    border-radius: 0.8em;
    text-align: center;
  }
}
