@use "sass:math";

.mini-cart-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  &__image {
    position: relative;
    flex: 0 0 15%;
    padding-right: math.div($boxSizeMargin, 2);
  }

  &__content {
    padding-left: math.div($boxSizeMargin, 2);
    flex: 0 0 85%;
  }

  &__name {
    font-size: $mediumSize;
    font-weight: $bold;
    margin-bottom: math.div($boxSizeMargin, 4);
    margin-right: $boxSizeMargin;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: math.div($boxSizeMargin, 4);

    > * {
      padding-left: $boxSizeMargin;
      padding-right: $boxSizeMargin;

      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__remove {
    position: absolute;
    top: 0.2rem;
    right: 0;
  }
}
