.map {
  max-width: 100%;
  height: 100%;
  border: 1px solid $shade03;

  &__info {
    &__name {
      font-weight: bold;
    }

    &__address {
      font-style: italic;
    }
  }
}
