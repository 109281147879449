@use "sass:math";

.account-orders-details {
  &__item {
    border-bottom: 1px solid $divider;
    padding: $boxSizeMargin 0;

    &__title {
      font-weight: bold;
    }
  }

  &__ordered-items {
    > *:first-child {
      padding-top: 0;
    }
    > *:last-child {
      border-bottom: none;
    }

    &:last-child > :last-child {
      padding-bottom: 0;
    }
  }

  &__total {
    padding: $boxSizeMargin 0 math.div($boxSizeMargin, 2);
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;
    margin-bottom: $boxSizeMargin;
    text-align: right;

    &:last-child {
      border-bottom: none;
    }

    .total {
      table {
        display: inline-block;
      }

      &__price {
        padding: 0;
      }
    }
  }

  &__comments {
    border-collapse: collapse;
    width: 100%;

    th {
      font-weight: $bold;
      padding: $boxSizeMargin math.div($boxSizeMargin, 2) $boxSizeMargin 0;
      border-bottom: 1px solid $shade03;
      text-align: left;
    }

    td {
      padding: $boxSizeMargin 0;
      border-bottom: 1px solid $shade03;
      width: 100%;
    }

    tr {
      &:first-child {
        th,
        td {
          padding-top: 0;
        }
      }
      &:last-child {
        th,
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  .recap-card__children {
    padding-bottom: 0;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .account-orders-details {
    &__comments {
      th {
        padding-right: $boxSizeMargin;
      }
    }
  }
}

@import "~./OrderDetailsLayout/OrderActions";

.quoted > * {
  margin-top: 0;
  margin-bottom: 0;
}
