.stars {
  position: relative;
  display: block;
  white-space: nowrap;

  &__fill {
    position: absolute;
    top: 0.1rem;
    left: 0;
    color: $brandPrimary;
    pointer-events: none;
    display: flex;
  }
  &__empty {
    color: $shade03;
    display: flex;
  }
  &--clickable &__empty &__icon:hover {
    color: $shade04;
  }

  &__fill,
  &__empty {
    margin: -0.1rem;
  }

  &__icon {
    padding: 0.1rem;

    > * {
      display: block;
    }
  }
}
