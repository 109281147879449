.push-block {
  position: relative;
  min-height: 18em;
  min-width: 30ch;
  padding: $boxSizeMargin * 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__content {
    max-width: 25ch;
  }

  @media screen and (min-width: $menuBreakpoint) {
    min-height: 26em;
    padding: $boxSizeMargin * 3;
  }
}
