.account-layout {
  @media screen and (min-width: $menuBreakpoint) {
    display: flex;

    &__nav {
      flex: 1;
      padding-right: $boxSizeMargin;
    }

    &__main {
      flex: 3;
      padding-left: $boxSizeMargin;
    }

    &__success-message {
      margin-bottom: $boxSizeMargin;
    }
  }
  @media screen and (min-width: 70em) {
    &__main {
      flex: 4;
    }
  }
}

@media print {
  .account-layout {
    &__nav {
      display: none;
    }
  }
}

@import "~./AccountTitle";
