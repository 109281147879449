.wishlist-product-grid {
  @media screen and (min-width: $menuBreakpoint) {
    display: flex;
    align-items: center;
    margin: -$boxSizeMargin;

    &__item {
      flex: 1;
      max-width: 50%;
      padding: $boxSizeMargin;
    }
  }
}
