@use "sass:math";

.cart-item {
  position: relative;
  margin: math.div(-$boxSizeMargin, 2);
  padding: $boxSizeMargin 0;

  &--loading {
    align-items: center;
  }

  &__cell {
    padding: math.div($boxSizeMargin, 2);

    &--actions {
      position: absolute;
      top: math.div($boxSizeMargin, 2);
      right: 0;
    }
  }

  @media screen and (min-width: 30em) {
    &__cell {
      &--actions {
        top: 2.2rem;
      }
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    display: table-row;

    &__cell {
      display: table-cell;
      padding: $boxSizeMargin * 1.5 0;
      vertical-align: middle;

      &--description {
        width: 50%;
      }

      &--actions {
        position: relative;
        top: auto;
        right: auto;
        width: 3em;
        text-align: center;
      }
    }
  }
}
