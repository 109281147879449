@use "sass:math";

.autocomplete-results {
  display: block;
  padding: 0;
  margin: math.div(-$boxSizeMargin, 2);
  list-style: none;

  &__option {
    padding: math.div($boxSizeMargin, 2);
    position: relative;
    display: block;

    &--selected {
      background: $shade02;
    }
  }
}
