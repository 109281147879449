.password {
  position: relative;

  &__show-icon {
    position: absolute;
    top: 0;
    right: 0;

    button {
      margin: auto;
      height: 100%;
    }
  }
}
