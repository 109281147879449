h1,
.h1 {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  line-height: 1.5;
}

h2,
.h2 {
  font-size: 1.55rem;
  font-weight: normal;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  line-height: 1.5;
}

h3,
.h3 {
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.5;
}

h4,
.h4 {
  font-size: 1.15rem;
  font-weight: normal;
  line-height: 1.4;
  margin-top: 1em;
  margin-bottom: 1em;
}

.mega {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (min-width: $menuBreakpoint) {
  h1,
  .h1 {
    line-height: 1.3;
    font-size: 3rem;
  }

  h2,
  .h2 {
    font-size: 2.1rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.3rem;
  }

  .mega {
    font-size: 4.5rem;
    line-height: 1.15;
  }
}
