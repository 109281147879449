@import "./tailwind.scss";
@import "~front-commerce/theme-chocolatine/web/theme/modules/modules";

.main-content {
  z-index: auto;
}

.blog-list,
.blog-post {
  a:hover,
  a:focus,
  .link:hover,
  .link:focus {
    text-decoration: none;
  }
}

$border-padding: 10px;
$label-placement: calc(30px + $border-padding);

.inspect-demo [data-demo] {
  position: relative;
  &:hover:after {
    content: "";
    border: 3px solid #b8124a;
    position: absolute;
    left: -$border-padding;
    top: -$border-padding;
    right: -$border-padding;
    bottom: -$border-padding;
    z-index: 10;
    pointer-events: none;
    border-radius: 15px;
    z-index: 4;
  }
  &:hover:before {
    content: attr(data-demo);
    position: absolute;
    top: -33px;
    background: #b8124a;
    color: white;
    padding: 0 10px;
    border-radius: 5px 5px 0 0;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    font-size: 1.125rem;
    line-height: 1.75rem;
    z-index: 4;
  }
}

.wysiwyg img {
  height: auto;
  max-width: 100%;
}
