.switch {
  $toggleSize: 1.875em;
  $switchSize: calc($toggleSize * 2);

  margin: 1em 0;
  display: block;

  &__main {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }

  &__interactive {
    cursor: pointer;

    input[type="checkbox"] {
      visibility: hidden;
      width: 0;
      height: 0;
    }

    label {
      display: block;
      position: relative;
      width: $switchSize;
      height: $toggleSize;
      border-radius: $toggleSize;
      background: $shade04;
      text-indent: -9999px;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        border-radius: $toggleSize;
        transition: 0.3s;
        top: $toggleSize * 0.1;
        left: $toggleSize * 0.1;
        width: $toggleSize * 0.8;
        height: $toggleSize * 0.8;
        background: #fff;
      }
    }

    input:checked + label {
      background: $brandPrimary;

      &::after {
        left: calc(100% - ($toggleSize * 0.1));
        transform: translateX(-100%);
      }
    }

    input:not(:disabled) + label:active::after {
      width: $toggleSize;
    }
  }

  &__description {
    margin-top: 0.3rem;
  }
}
