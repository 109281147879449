@media only screen {
  .checkbox {
    position: relative;

    &--center {
      text-align: center;
    }

    input[type="checkbox"] {
      position: absolute;
      overflow: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    label {
      position: relative;
      padding-left: 2rem + $boxSizeMargin;
      min-height: 2rem;
      padding-top: 0.15rem;
      display: inline-block;
    }

    input[type="checkbox"]:disabled + label {
      color: $shade03;
    }

    input[type="checkbox"] + label::before {
      position: absolute;
      left: 0;
      top: 0rem;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      content: "";
      cursor: pointer;
      text-align: center;
      border: 1px solid $divider;
      box-sizing: border-box;
    }

    input[type="checkbox"]:checked + label:before {
      content: "✔";
      background-color: $black;
      color: $white;
      font-size: 1.5rem;
      line-height: 2rem;
      border: none;
    }

    input[type="checkbox"]:not(:disabled):focus + label:before,
    input[type="checkbox"]:not(:disabled):hover + label:before {
      outline: 2px solid $brandPrimary;
      outline-offset: -3px;
    }
  }
}
