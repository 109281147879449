.slider {
  overflow: hidden;
  &--overflow-visible {
    overflow: visible;
  }

  &__content {
    position: relative;
    &--animate {
      transition: transform 0.3s ease-in-out;
    }
  }

  &__slide {
    outline: none;
    &--prev,
    &--next {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &--prev {
      transform: translate(-100%);
    }
    &--next {
      transform: translate(+100%);
    }
  }

  &--vertical {
    height: 100%;
    .slider__content,
    .slider__slide {
      height: 100%;
    }

    .slider__slide {
      &--prev {
        transform: translate(0, -100%);
      }
      &--next {
        transform: translate(0, +100%);
      }
    }
  }
}
