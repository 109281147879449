@use "sass:math";

.storecredit-history {
  width: 100%;
  border-collapse: collapse;
  font-size: $extraSmallSize;

  thead {
    border-bottom: 2px solid $shade05;
  }

  td,
  th {
    padding: math.div($boxSizeMargin, 2) math.div($boxSizeMargin, 3);
    text-align: left;
  }

  &__operation {
    border-bottom: 1px solid $shade03;
  }
}
