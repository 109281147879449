@use "sass:math";

.breadcrumb {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: math.div($boxSizeMargin, 4) 0;
  margin: 0;

  &__item {
    display: flex;
    align-items: center;
    color: $shade04;

    &::after {
      display: block;
      content: "/";
      padding: 2px math.div($boxSizeMargin, 2) 0;
    }

    &:last-child {
      color: inherit;

      &::after {
        display: none;
      }
    }
  }
  @media screen and (min-width: $menuBreakpoint) {
    display: flex;
    padding: $boxSizeMargin 0;

    &__item {
      &::after {
        padding: 2px $boxSizeMargin 0;
      }
    }
  }
}
