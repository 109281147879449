@use "sass:math";

.cart-item-actions {
  display: flex;
  justify-content: center;
  margin: math.div(-$boxSizeMargin, 4);

  &--loading {
    display: none;
  }

  &__item {
    padding: math.div($boxSizeMargin, 4);
  }

  @media screen and (min-width: $menuBreakpoint) {
    flex-direction: column;
  }
}
