@use "sass:math";

.cookie-grid {
  & + & {
    padding-top: math.div($boxSizeMargin, 2);
  }

  &__title {
    background: $shade02;
    padding: $boxSizeMargin $boxSizeMargin math.div($boxSizeMargin, 2);
    > :first-child {
      text-align: left;
      margin: 0;
    }
  }

  &__description {
    background: $shade02;
    text-align: left;
    padding: 0 $boxSizeMargin $boxSizeMargin;
  }

  &__children {
    padding-top: math.div($boxSizeMargin, 2);
  }
}
