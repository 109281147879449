.item-with-addon {
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  justify-content: space-between;
  align-items: center;
  max-width: 30em;
  margin: 0 auto;

  &__name {
    font-weight: bold;
    > img {
      display: block;
      max-width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__addon {
    box-sizing: border-box;
  }

  &__description {
    flex: 0 0 80%;
    padding-left: 0;
  }
}
