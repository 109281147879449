.bottombar {
  border-top: 1px solid $divider;
  padding-top: $boxSizeMargin * 2;
  &__aside {
    margin-bottom: $boxSizeMargin * 2;
  }
  margin-bottom: $boxSizeMargin * 2;
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .bottombar {
    border-top: none;
    padding-top: 0;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &__aside {
      flex: 3;
      margin-right: $boxSizeMargin * 2;
      min-width: 30ch;

      p {
        max-width: 45ch;
      }
    }

    &__links {
      flex: 4;
      display: flex;
      justify-content: flex-start;
    }

    &__list {
      width: 33%;
      min-width: 20ch;
    }
  }
}

.brand {
  color: $shade04;

  &__logo {
    margin-bottom: $boxSizeMargin;
  }
  &__synthesis {
    margin-top: $boxSizeMargin;
    margin-bottom: $boxSizeMargin;
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__synthesis {
      margin-top: $boxSizeMargin * 2;
      margin-bottom: $boxSizeMargin * 2;
    }
  }
}

@media print {
  .bottombar {
    display: none;
  }
}

@import "~./Brand";
