.orders-page {
  max-width: $menuBreakpoint;
  margin: auto;
  padding: (2 * $boxSizeMargin) $boxSizeMargin;
  &__title {
    text-align: center;
  }
  &__loading-message {
    padding-inline-start: $boxSizeMargin * 2;
  }
}
