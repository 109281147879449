.product-view {
  overflow: hidden;

  &__gallery {
    position: relative;
  }

  &__actions {
    position: absolute;
    top: $boxSizeMargin * 1.5;
    right: $boxSizeMargin * 1.5;
    @media screen and (min-width: $menuBreakpoint) {
      display: none;
    }
  }
}
