@use "sass:math";

.selector {
  position: relative;
  border: none;
  padding: 0;
  margin: 0 5px;
  display: block;
  font-size: 0.8em;
  margin-left: math.div(-$boxSizeMargin, 2);
  margin-right: $boxSizeMargin;
  text-transform: uppercase;

  &__list {
    display: none;

    &:focus-within {
      outline: none;
    }
  }

  &--opened &__list {
    position: absolute;
    display: block;
    width: 10ch;
    border: 1px solid $divider;
    background: $white;
    padding: math.div($boxSizeMargin, 2);
    top: 150%;
    z-index: 1;
  }
  &__item > * {
    text-transform: uppercase;
  }

  > :first-child {
    margin-left: 0;
    width: 100%;
  }
  > :last-child {
    margin-right: 0;
  }
  &--active {
    font-weight: bold;
    text-decoration: underline;
    cursor: default;
  }
}
@media screen and (min-width: #{$menuBreakpoint}) {
  .selector {
    font-size: $regularSize;
  }
}
