.account-orders-invoice {
  &__actions {
    margin-bottom: $boxSizeMargin;
  }
}

@media print {
  .account-orders-invoice {
    &__actions {
      display: none;
    }
  }
}
