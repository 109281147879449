@use "sass:math";

.footer-legislation {
  color: $shade04;
  border-top: 1px solid $divider;
  text-transform: uppercase;
  &__list {
    display: block;
    list-style-type: none;
    text-align: center;
  }

  @media screen and (min-width: $menuBreakpoint) {
    & {
      padding-top: $boxSizeMargin;
      padding-bottom: $boxSizeMargin;

      &__item {
        display: inline-block;

        & + & {
          &::before {
            display: inline-block;
            padding-left: math.div($boxSizeMargin, 2);
            padding-right: math.div($boxSizeMargin, 2);
            content: "-";
          }
        }
      }
    }
  }
}

@media print {
  .footer-legislation {
    display: none;
  }
}
