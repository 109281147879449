@use "sass:math";

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $boxSizeMargin;

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: math.div($boxSizeMargin * 3, 2) 0;
  }

  &__price {
    font-size: $extraLargeSize;
  }

  &__gallery {
    width: 500px;
    max-width: 100%;
    align-self: flex-start;
    margin: 0 auto;

    &__main {
      position: relative;
      width: 100%;
      margin-bottom: $boxSizeMargin;

      &__zoom {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: transparent;
        border: 0;

        &--loading {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &__slider {
      border-top: 1px solid $divider;
      padding: 1em 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 1em;

      &--prev,
      &--next,
      &__image {
        padding: 0 0.5em;
      }
      &__image {
        position: relative;
        width: 100%;
        max-height: 10em;
      }
      &--prev,
      &--next {
        position: absolute;
      }
      &--prev {
        right: 100%;
      }
      &--next {
        left: 100%;
      }
    }
  }

  &__synthesis {
    margin: auto;
    max-width: 400px;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba($shade05, 0.35);
    padding: $boxSizeMargin;
  }

  &__add-to-cart {
    margin: 2em 0;
  }

  &__out-of-stock {
    font-size: $mediumSize;
    color: $shade05;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .product {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    &__gallery,
    &__synthesis {
      margin: 2em;
    }
    &__gallery {
      flex: 1.2;
      min-width: 250px;
    }
    &__synthesis {
      min-width: 250px;
      width: 33%;
      flex: 1.2;
      flex: 1;
      .configurator {
        max-width: 100%;
      }
    }
  }
}
