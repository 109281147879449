@use "sass:math";

.product-picker {
  position: relative;
  padding-top: $boxSizeMargin;
  padding-bottom: $boxSizeMargin;

  &__suggestions {
    background-color: $white;
    padding: $boxSizeMargin;
    padding-top: 0;
    margin-top: math.div(-$boxSizeMargin, 2);
    box-shadow: 0 8px 15px 0 rgba($shade05, 0.05);
  }
}
