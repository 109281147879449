@use "sass:math";

.navigation {
  &__list {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-right: math.div(-$boxSizeMargin, 2);
  }

  &__item {
    display: block;
    margin: math.div($boxSizeMargin, 2);
  }

  &__sub {
    position: relative;
    &__backdrop {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      background: $shade05;
      border: 0;
      height: 100vh;
      width: 100vw;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      color: transparent;
    }

    &__content {
      position: absolute;
      width: 100%;
      background: white;
      z-index: 1;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      transform: translate(0, -100%);
      opacity: 0;

      &:focus {
        outline: none;
      }
    }

    &--aside {
      .navigation__sub__content {
        position: absolute;
        max-width: 95vw;
        width: 30em;
        right: 0;
        top: 0;
        min-height: 80vh;
        transform: translate(100%, 0);
      }
    }

    &--opened {
      .navigation__sub__backdrop {
        opacity: 0.5;
      }
      .navigation__sub__content {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}

@import "~./Menu/MenuNavigationMobile";
@import "~./Menu/MenuMainCategories";
@import "~./Menu/CategoriesMenu";
@import "~./AccountNavigation/AccountSubNavigation";
