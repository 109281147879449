.titled-card {
  &__title {
    text-align: center;

    > * {
      margin: 0;
    }
  }

  &__description {
    text-align: center;

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
}
