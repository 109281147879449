.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $shade05;
    opacity: 0.3;
  }

  &--backdrop-dark {
    &::after {
      opacity: 0.5;
    }
  }
}
