.wysiwyg {
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $bold;
  }
  p,
  ul,
  ol,
  pre {
    margin: $smallSize 0;
  }
  h1 {
    font-size: $extraLargeSize;
    margin: $tinySize 0;
  }
  h2 {
    font-size: $mediumSize;
    margin: $smallSize 0;
  }
  h3 {
    font-size: $mediumSize;
    margin: $smallSize 0;
  }
  h4 {
    font-size: $smallSize;
    margin: $mediumSize 0;
  }
  h5 {
    font-size: $extraSmallSize;
    margin: $regularSize 0;
  }
  h6 {
    font-size: $tinySize;
    margin: $extraLargeSize 0;
  }
  a {
    text-decoration: underline;
  }
  ul,
  ol {
    padding-left: $giantSize;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    display: list-item;
  }
  em,
  address {
    font-style: italic;
  }
  pre {
    font-family: monospace;
    white-space: pre;
  }
  blockquote {
    margin: $smallSize $giantSize;
  }
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: $shade04;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
}
