@use "sass:math";

.confirmation-card {
  text-align: center;

  &__indicator {
    color: $success;
    margin: $boxSizeMargin auto;
    &--pending {
      color: $info;
    }
  }
  &__title {
    color: currentColor;
    > * {
      margin: 0 0 math.div($boxSizeMargin, 2) 0;
    }
  }
  &__confirm {
    margin: $boxSizeMargin * 2 $boxSizeMargin $boxSizeMargin;
  }
}
