.brand {
  color: $shade04;

  &__logo {
    margin-bottom: $boxSizeMargin;
  }
  &__synthesis {
    margin-top: $boxSizeMargin;
    margin-bottom: $boxSizeMargin;
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__synthesis {
      margin-top: $boxSizeMargin * 2;
      margin-bottom: $boxSizeMargin * 2;
    }
  }
}
