@use "sass:math";

.icon-list {
  display: block;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -$boxSizeMargin;
  margin-right: -$boxSizeMargin;

  > * {
    margin: 0;
  }

  a {
    display: block;
    padding: math.div($boxSizeMargin, 2) $boxSizeMargin;
    color: currentColor;

    &:focus,
    &:hover {
      color: $fontColor;
    }
  }
  svg {
    display: block;

    path {
      fill: currentColor;
    }
  }
}
