@use "sass:math";

.postal-address-item {
  &__name {
    font-weight: bold;
  }
  &__icon {
    > * {
      display: inline-block;
      height: 1.2em;
      vertical-align: text-bottom;
      margin-right: 0.3rem;
    }
  }

  &__schedule {
    margin-top: math.div($boxSizeMargin, 3);
    font-size: $extraSmallSize;

    > :first-child {
      font-weight: $bold;
    }

    .schedule {
      margin-top: math.div($boxSizeMargin, 3);
    }
  }
}
