.alert {
  background: $white;
  border: 1px solid currentColor;
  border-radius: 2rem;
  padding: $boxSizeMargin;

  &--success {
    color: $success;
  }
  &--error {
    color: $warning;
  }
  &--warn {
    color: $info;
  }
  &--info {
    color: $shade04;
  }

  &__content {
    text-align: left;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &__message {
      margin-left: $boxSizeMargin;

      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
  }
}
