@use "sass:math";

.cookie-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid $shade02;
  margin: 0;
  padding: 0 math.div($boxSizeMargin, 2);
  transition: background-color 0.2s ease-in-out;
  &:last-child {
    border-bottom: none;
  }
  &:hover,
  &:focus-within {
    background: $shade02;
  }
  &__info {
    flex: 1;
    min-width: 15em;
    padding: math.div($boxSizeMargin, 2);
    > :first-child {
      margin: 0;
    }
    &__description {
      margin-top: math.div($boxSizeMargin, 4);
      font-size: $extraSmallSize;

      > *:nth-child(n + 2) {
        margin-left: $boxSizeMargin;
      }
    }
  }
  &__actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: math.div(-$boxSizeMargin, 2);
    padding: math.div($boxSizeMargin, 2);
    > * {
      padding: math.div($boxSizeMargin, 2);
    }
  }
  &__consent-options {
    width: 100%;
    flex: auto;

    &-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
