.order-status {
  &--progress {
    color: $success;
  }
  &--warning {
    color: $warning;
  }
  &--danger {
    color: $red;
  }
}
