.responsive-image {
  position: relative;
  display: inline-block;

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  > :nth-child(2) {
    pointer-events: none;
  }

  .responsive-image > picture:nth-child(2) {
    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    .responsive-image--loading & {
      opacity: 1;
    }
  }

  &__actual {
    position: relative;
    transition: opacity 0.2s ease-in-out;
    .responsive-image--loading & {
      opacity: 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $shade03;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: $shade02;
    background-image: linear-gradient(
      90deg,
      $shade02,
      lighten($shade02, 0.5),
      lighten($shade02, 1),
      lighten($shade02, 2),
      lighten($shade02, 3),
      lighten($shade02, 2),
      lighten($shade02, 1),
      lighten($shade02, 0.5),
      $shade02
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: transparent;
    animation: skeletonAnimation 2s infinite;
    pointer-events: none;
    user-select: none;
    z-index: 2;
    opacity: 0;
  }

  &--full,
  &--full-rounded {
    display: block;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }

    .responsive-image__actual,
    .responsive-image__placeholder {
      position: absolute;
      top: 0;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
      object-fit: contain;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /*
         * IE hack
         * width:100% is needed for styling errors that couldn't be styled using the default error image
         * but width:auto is needed in IE11 to make sure that images are not squashed
         */
        width: auto;
      }
    }

    &::after {
      content: "";
      position: relative;
      width: auto;
      height: auto;
      padding-top: 50%;
      padding-top: var(--image-ratio);
      display: block;
    }
  }

  &--rounded,
  &--full-rounded {
    img {
      border-radius: 50%;
    }

    &::after {
      border-radius: 50%;
    }
  }

  &--cover {
    .responsive-image__actual,
    .responsive-image__placeholder {
      object-fit: cover;
      width: 100%;
    }
  }

  &--loading {
    .responsive-image__placeholder {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }
}
