@use "sass:math";

.layer-header {
  display: flex;
  align-items: center;
  margin: 0 math.div(-$boxSizeMargin, 2);
  &__title,
  &__actions {
    padding: 0 math.div($boxSizeMargin, 2);
  }

  &__title {
    flex: 1;
    > * {
      margin: 0;
    }
  }

  &__total {
    color: $shade04;
  }
}
