@use "sass:math";

.product-overview {
  &__name {
    font-size: $largeSize;
  }

  &__price {
    font-size: $veryLargeSize;
    margin-top: math.div($boxSizeMargin, 4);
    text-decoration: none;
    display: inline-block;
  }

  &__sold-out {
    color: $shade04;
    font-size: $regularSize;
    min-height: 2.5rem;
  }

  &__rating {
    margin-top: math.div($boxSizeMargin, 4);
  }
}
