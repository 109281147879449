.explained-call-to-action {
  &__title {
    text-align: center;
    > * {
      margin-top: 0;
    }
  }
  &__content {
    margin: $boxSizeMargin * 2 auto;
    max-width: 40em;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__call-to-action {
    text-align: center;
    margin: $boxSizeMargin auto 0;
  }
}
