@use "sass:math";

.order-details-layout {
  &__back {
    margin-bottom: $boxSizeMargin;
  }
  &__header {
    margin-bottom: $boxSizeMargin * 2;
  }
  &__title {
    > * {
      margin: 0;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: math.div($boxSizeMargin, 2);
  }

  @media screen and (min-width: 30em) {
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
      align-items: center;
    }
    &__title {
      flex: 1;
    }
    &__actions {
      margin-top: 0;
    }
  }
}
