@use "sass:math";

.order-return-authorization {
  margin-top: $boxSizeMargin;

  &__info {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__id {
    font-weight: bold;
  }

  &__items {
    li {
      margin: $boxSizeMargin 0;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1rem;

      thead {
        border-bottom: 2px solid $shade05;
      }

      td,
      th {
        padding: math.div($boxSizeMargin, 4) * 3 0;
      }

      tbody tr {
        border-bottom: 1px solid $shade03;
      }

      th {
        text-align: left;
      }
    }
  }

  &__returned-item-title {
    cursor: pointer;
  }
}
