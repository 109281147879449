.product-gallery {
  display: flex;
  flex-direction: row-reverse;

  &__main {
    flex: 5;
    align-self: flex-start;
    position: relative;
  }

  &__zoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: transparent;
    border: 0;

    &--loading {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__nav {
    position: absolute;
    bottom: $boxSizeMargin * 1;
    left: $boxSizeMargin * 2;
    right: $boxSizeMargin * 2;
    display: flex;
  }
  &__button {
    position: relative;
    display: block;
    flex: 1;
    height: $boxSizeMargin * 2;
    background: none;
    color: transparent;
    font-size: 0;
    border: none;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background: white;
    }

    &:hover,
    &:focus {
      outline: none;
      &::after {
        height: 6px;
        background: $black;
      }
    }
    &--current {
      &::after {
        background: $black;
      }
    }
  }

  &__aside {
    display: none;
    flex: 1;
  }

  &__preview-button {
    position: relative;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid $shade03;
      }
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__aside {
      display: block;
      max-width: 15%;
      min-width: 7.2rem;
      padding-right: $boxSizeMargin * 2;
    }

    &__nav {
      display: none;
    }
  }
}
