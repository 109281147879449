@use "sass:math";

.grouped-item-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: math.div(-$boxSizeMargin, 4);

  &__details {
    flex: 1;
    padding: math.div($boxSizeMargin, 4);
    min-width: 10rem;
  }
  &__quantity {
    padding: math.div($boxSizeMargin, 4);
    min-width: 8.3rem;
    text-align: center;
  }
}
