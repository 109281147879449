@use "sass:math";

.order-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: 2px solid $shade05;
  }

  td,
  th {
    padding: math.div($boxSizeMargin, 4) * 3 0;
  }

  tbody tr {
    border-bottom: 1px solid $shade03;
  }

  th {
    text-align: left;
  }

  margin-bottom: 1rem;
}
