.account-title {
  > * {
    margin: 0;
  }
}

@media print {
  .account-title {
    display: none;
  }
}
