.skeleton {
  position: relative;
  display: inline-block;

  &::after {
    z-index: 2;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";

    background-color: $shade02;
    background-image: linear-gradient(
      90deg,
      $shade02,
      lighten($shade02, 0.5),
      lighten($shade02, 1),
      lighten($shade02, 2),
      lighten($shade02, 3),
      lighten($shade02, 2),
      lighten($shade02, 1),
      lighten($shade02, 0.5),
      $shade02
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    color: transparent;
    animation: skeletonAnimation 3s infinite;
    pointer-events: none;
    user-select: none;
  }
}

@keyframes skeletonAnimation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
