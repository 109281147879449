/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.sr-only,
.sr-only-desktop,
.sr-only-mobile {
  display: block !important;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  background: none;
}
@media screen and (max-width: #{$menuBreakpoint - 0.01em}) {
  .sr-only-desktop {
    display: block !important;
    position: static;
    height: auto;
    width: auto;
    margin: 0;
    white-space: normal;
    clip: auto;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .sr-only-mobile {
    display: block !important;
    position: static;
    height: auto;
    width: auto;
    margin: 0;
    white-space: normal;
    clip: auto;
  }
}

.sr-focusable:active,
.sr-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: normal;
  width: auto;
  position: static;
}
