.mini-cart-content {
  height: 100%;
  overflow-y: auto;

  > * {
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    height: 100%;
  }

  &__main {
    padding-top: $boxSizeMargin;
    flex: 1;
  }

  &__total {
    padding-top: $boxSizeMargin * 2;
    padding-bottom: 8em;
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__main {
      padding-top: $boxSizeMargin * 2;
    }

    &__total {
      padding-top: $boxSizeMargin * 4;
      padding-bottom: 10em;
    }
  }
}

@import "~./MiniCartTitle";
@import "~./MiniCartTotal";
@import "~./MiniCartActions";
