@use "sass:math";

.header {
  background: $white;
  border-bottom: 1px solid $divider;
  position: relative;
  z-index: 3;

  &__container {
    min-height: 7em;
    display: flex;
    align-items: center;
    justify-content: stretch;

    @media screen and (min-width: $menuBreakpoint) {
      & {
        min-height: 8em;
      }
    }
  }

  &__central {
    margin: 0 math.div($boxSizeMargin, 2);
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__sub {
    position: relative;
    z-index: 2;
  }

  &__store {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > * {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &:empty {
      display: none;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__logo {
      flex: 1;
    }
    &__central {
      flex: 5;
    }
    &__links {
      flex: 1;
    }
  }
}

@media print {
  .header {
    border: 0;

    &__container {
      min-height: unset;

      > * {
        display: none;
      }
    }
    &__logo {
      display: block;
    }
  }
}

@import "~./Navigation/Navigation";
