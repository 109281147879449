.price-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: $shade05;

  &__input {
    text-align: center;
    max-width: 5rem;
    background: $white;
    border: 1px solid $shade03;
    padding-left: 0;
    margin: 0 0.3rem;
  }

  &__currency {
    padding: 0 0.3rem 0 0;
  }
}
