@use "sass:math";

.form-item {
  display: flex;
  text-align: left;
  flex-direction: column;

  &__label > * {
    width: 100%;
    align-self: center;
  }

  &__input > * {
    width: 100%;
    align-self: center;
  }

  &__label + &__input > * {
    margin-top: math.div($boxSizeMargin, 2);
  }

  &--inline {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    .form-item__label {
      margin-right: $boxSizeMargin;
    }

    .form-item__label > *,
    .form-item__input > * {
      width: auto;
    }

    .form-item__input > * {
      margin-top: 0;
    }
  }
}
