.cookie-notice {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $white;
  border-top: 1px solid $shade05;
  color: $black;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  z-index: 30;
  text-transform: none;
  padding: $boxSizeMargin;

  &__content {
    > * {
      margin: 0;
    }
  }

  &__modal {
    position: relative;
    overflow: auto;
    border-radius: 5px;
    outline: none;
    margin: auto;
    max-width: 90%;
    background: $white;
    padding: 0;
    box-sizing: border-box;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .cookie-notice {
    padding: $boxSizeMargin;

    &__modal {
      width: 70%;
      max-width: 80em;
    }
  }
}
