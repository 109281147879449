@use "sass:math";

.items-slider {
  overflow: hidden;
  &__container {
    position: relative;
    padding-right: $boxSizeMargin * 2;
  }
  &__slider {
    position: relative;
    margin: (-$boxSizeMargin);
  }
  &__item {
    display: flex;
    justify-content: center;
  }

  &__element {
    flex: 1;
    padding: $boxSizeMargin;
    display: flex;
    align-items: stretch;
    max-width: 50%;
    > * {
      width: 100%;
      height: 100%;
    }
  }

  &__controls {
    display: none;
    margin-top: $boxSizeMargin;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      color: $fontColor;
      border: none;
      width: $boxSizeMargin * 2;
      height: $boxSizeMargin * 2;
      margin-left: math.div($boxSizeMargin, 2);
      margin-right: math.div($boxSizeMargin, 2);
      transition: transform 0.15s ease-in-out;

      &:hover,
      &:focus {
        transform: scale(1.05);
      }

      &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__controls {
      display: flex;
    }
  }

  @media screen and (min-width: $containerWidth) {
    &__container {
      padding-right: 0;
    }
    &__slider {
      overflow: hidden;
    }

    &__controls {
      display: block;
    }
    &__previous,
    &__next {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    &__previous {
      left: -$boxSizeMargin * 4;
    }
    &__next {
      right: -$boxSizeMargin * 4;
    }

    &__element {
      max-width: 25%;
    }
  }

  // By putting this after the media query, it takes precedence over the rules in the
  // media query. This is intended because there is no difference of the vertical
  // view in mobile or desktop.
  &--vertical {
    &,
    > *,
    .items-slider__container,
    .items-slider__item {
      height: 100%;
    }

    .items-slider__container {
      padding-right: 0;
    }

    .items-slider__slider {
      height: calc(100% - #{$boxSizeMargin * 4});
      margin-bottom: 0;
      overflow: hidden;
    }
    .items-slider__element {
      width: 100%;
      max-width: 100%;
      &:empty {
        flex: reset;
        padding: 100% 0 0 0;
      }
    }

    .items-slider__item {
      flex-direction: column;
      justify-content: flex-start;
    }

    .items-slider__controls {
      display: flex;
      justify-content: center;
      margin-top: 0;
      padding-top: $boxSizeMargin;
    }

    .items-slider__previous,
    .items-slider__next {
      position: static;
      transform: none;
    }
  }
}
