.autocomplete {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;

  background: $white;
}

@import "~./AutocompleteResults/AutocompleteResults";
@import "~./AutocompleteItem/AutocompleteItem";
