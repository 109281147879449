.newsletter {
  position: relative;

  &__content {
    padding: $boxSizeMargin * 1.5;
  }

  &__title {
    margin-bottom: $boxSizeMargin;
    > * {
      margin: 0;
    }
  }
}

@media screen and (min-width: 55em) {
  .newsletter {
    &__content {
      display: flex;
      align-items: center;
      padding: $boxSizeMargin * 5 $boxSizeMargin * 6;
    }

    &__title {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: $smallSize;
      margin: 0;
    }
    &__subscribe {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding: $boxSizeMargin 0 $boxSizeMargin $boxSizeMargin;
    }
  }
}
