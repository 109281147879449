.stepped-progress {
  &__list {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__item {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0;
    padding: 0;

    &--next {
      color: $shade03;
    }
    &--selected {
      color: $brandPrimary;
    }
    &--passed {
      color: $brandPrimary;
    }

    &::after {
      content: "";
      display: block;
      height: 1px;
      background: $shade03;
      flex: 1;
      margin: 0 $boxSizeMargin;
    }
    &:last-child {
      flex: 0;
      &::after {
        display: none;
      }
    }
  }

  &__button {
    background: none;
    border: none;
    padding: 0;
    color: inherit;
  }

  &--navigable {
    .stepped-progress__item {
      &--passed {
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
      &--next {
        cursor: not-allowed;
      }
    }
  }
}
