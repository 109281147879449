.empty-cart {
  text-align: center;
  margin: $boxSizeMargin * 4 auto $boxSizeMargin * 4;
  max-width: calc(100% - #{$boxSizeMargin * 2});

  &__redirection {
    max-width: 40ch;
    margin: $boxSizeMargin * 2 auto;
  }
}
