@use "sass:math";

.categories-menu {
  display: flex;
  flex-wrap: wrap;
  margin: math.div($boxSizeMargin, 2) (-$boxSizeMargin);
  &__list {
    margin: 0 $boxSizeMargin;
    min-width: 35ch;
  }

  @media screen and (min-width: $menuBreakpoint) {
    margin-top: $boxSizeMargin;
    margin-bottom: $boxSizeMargin;

    &__list {
      margin-top: math.div($boxSizeMargin, 2);
      margin-bottom: math.div($boxSizeMargin, 2);
    }
  }
}
