@use "sass:math";

.cart-header {
  display: none;
}

@media screen and (min-width: $menuBreakpoint) {
  .cart-header {
    display: table-row;
    font-weight: $bold;
    color: $shade04;
    &__item {
      padding: math.div($boxSizeMargin, 2) 0;
      display: table-cell;
    }
  }
}
