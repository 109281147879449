@use "sass:math";

.wishlist-product-item {
  width: 100%;
  display: flex;
  align-items: center;

  &__cell {
    &--description {
      flex: 1;
    }
  }
}
.wishlist-product-details {
  display: flex;
  align-items: center;

  &__image {
    max-width: 10em;
    min-height: 7em;
    min-width: 0;
    width: 7em;
    align-self: center;
    color: $shade04;
    padding-right: $boxSizeMargin;

    display: flex;
    align-items: center;
  }
  &__image-container {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__details {
    padding-left: $boxSizeMargin;
  }

  &__name {
    > * {
      margin: 0;
    }
  }
}

.wishlist-item-actions {
  display: flex;
  margin: math.div(-$boxSizeMargin, 2);
  flex-wrap: wrap;
  &__item {
    padding: math.div($boxSizeMargin, 2);
  }
}
