@use "sass:math";

.layer-sorting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: math.div(-$boxSizeMargin, 2);

  &__element {
    padding: math.div($boxSizeMargin, 2);
    font-weight: $bold;
  }

  &--standalone {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: math.div(-$boxSizeMargin, 2) (-$boxSizeMargin);

    &__element {
      padding: math.div($boxSizeMargin, 2) $boxSizeMargin;
    }
  }
}
