.labelled-icon {
  display: inline-block;
  text-align: center;
  margin: 0;

  &__content {
    display: flex;
    width: auto;
    align-items: center;

    > :first-child {
      display: block;
      margin-right: $boxSizeMargin;

      &.labelled-icon__icon {
        display: flex;
      }
    }
  }

  &__icon {
    display: flex;
  }

  &--block {
    display: block;
  }

  &--vertical {
    .labelled-icon {
      &__content {
        flex-direction: column;

        > * + * {
          margin-top: 0.2rem;
        }

        > :first-child {
          margin-right: 0;
        }
      }
    }
  }

  &--icon-round {
    .labelled-icon {
      &__icon {
        color: $brandPrimary;
      }
    }
  }
}
