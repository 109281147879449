@use "sass:math";

.product-reviews-synthesis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: math.div($boxSizeMargin, 8) math.div(-$boxSizeMargin, 2);

  &__stars,
  &__actions {
    padding: math.div($boxSizeMargin, 8) math.div($boxSizeMargin, 2);
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    margin: math.div(-$boxSizeMargin, 8) math.div(-$boxSizeMargin, 2);
  }
  &__link {
    padding: math.div($boxSizeMargin, 8) math.div($boxSizeMargin, 2);
  }
}
