@use "sass:math";

.icon {
  object-fit: contain;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: middle;

  &--small {
    width: 1.4rem;
    height: 1.4rem;
  }
  &--mini {
    width: 1rem;
    height: 1rem;
  }
  &--big {
    width: 2.7rem;
    height: 2.7rem;
  }
}

.icon-wrapper {
  > * {
    display: block;
  }

  &--default-block {
    display: block;
  }
  &--default {
    display: inline-block;
    vertical-align: middle;
  }
  &--block {
    display: inline-block;
    position: relative;
    padding: 0.5rem;

    &::before {
      content: "";
      position: absolute;
      background: currentColor;
      border-radius: 0.5rem;
      opacity: 0.1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &.icon-wrapper--big {
      padding: 1rem;
      &::before {
        border-radius: 0.7rem;
      }
    }
  }

  &--round,
  &--round-border {
    display: inline-block;
    position: relative;
    padding: math.div($boxSizeMargin, 2);
    border-radius: $boxSizeMargin * 2;
  }
  &--round {
    background: currentColor;
    > * {
      color: $white;
    }
  }
  &--round-border {
    border: 1px solid currentColor;
  }
}
