@use "sass:math";

.gsc {
  display: flex;
  justify-content: center;

  &__open {
    margin-left: math.div($boxSizeMargin, 2);
  }
}
