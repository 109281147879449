.box {
  background: $white;
  border: 1px solid $divider;
  padding: $boxSizeMargin * 1.5;
}

.box-title {
  text-align: center;
  > * {
    margin: 0;
  }
}
