@use "sass:math";

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.spinner {
  $spinnerSize: $boxSizeMargin * 2;
  $spinDuration: 1.2s;

  display: block;
  position: relative;
  margin: auto;
  height: $spinnerSize;
  max-width: 100%;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $spinnerSize;
    height: $spinnerSize;
    margin-top: math.div(-$spinnerSize, 2);
    margin-left: math.div(-$spinnerSize, 2);
    border-radius: 50%;
    border: 0.25rem solid $shade03;
    border-top-color: $brandPrimary;
    animation: spin $spinDuration infinite cubic-bezier(0.7, 0.15, 0.3, 0.85);
  }
}
