@use "sass:math";

.one-line-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__input {
    margin-bottom: $boxSizeMargin;
  }

  &--button-icon {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    .one-line-form__input {
      flex: 1;
      min-width: 15ch;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 30em) {
    align-items: flex-start;
    flex-direction: row;
    &__input {
      margin-right: math.div($boxSizeMargin, 2);
      margin-bottom: 0;
    }
    &__button {
      margin-left: math.div($boxSizeMargin, 2);
    }
  }
}
