@use "sass:math";

.cookie-page {
  &__description {
    text-align: left;
    padding: 0 0 $boxSizeMargin * 2;
  }

  &__children {
    &__actions {
      margin: $boxSizeMargin * 2 0;
      text-align: center;
      font-size: $mediumSize;
    }
  }

  &__global-cookies {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: $boxSizeMargin 0 $boxSizeMargin * 2;
    margin: math.div(-$boxSizeMargin, 2);
    &__title {
      font-size: $mediumSize;
      padding: math.div($boxSizeMargin, 2);
      > :first-child {
        margin: 0;
      }
    }
    &__actions {
      padding: math.div($boxSizeMargin, 2);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      margin: math.div(-$boxSizeMargin, 2);
      > * {
        padding: math.div($boxSizeMargin, 2);
      }
    }
  }

  @media screen and (min-width: 40em) {
    &__global-cookies {
      justify-content: space-between;
    }
  }
  @media screen and (min-width: #{$menuBreakpoint}) {
    &__global-cookies {
      &__title {
        padding-bottom: 0;
      }
    }
  }
}
