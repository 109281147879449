@use "sass:math";

.order-recap {
  list-style: none;
  padding-left: 0;

  > li {
    margin-bottom: math.div($boxSizeMargin, 4);

    strong {
      margin-right: 0.25em;
    }
  }
}
