.modal {
  position: relative;
  width: 100%;
  max-width: 30em;
  max-height: 100%;
  outline: none;

  transform: scale(1.1) translate(0, -1em);
  transform-origin: center center;
  transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  opacity: 0;

  &--opened {
    transition: transform 0.2s ease-out, opacity 0.1s ease-out;
    transform: translate(0, 0);
    opacity: 1;
  }

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $boxSizeMargin 0;
    z-index: 50;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    transition: opacity 0.2s ease-in;
    transition-delay: 0.1s;
    opacity: 0;

    &--opened {
      transition: opacity 0.2s ease-out;
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  &__content {
    position: relative;
    overflow: auto;
    outline: none;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    padding: $boxSizeMargin * 1.5;
    box-sizing: border-box;

    &__main {
      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    text-align: center;
    padding-left: $boxSizeMargin;
    padding-right: $boxSizeMargin;
    font-weight: bold;
    > :first-child {
      margin-top: 0;
      padding-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    text-align: right;
    color: $fontColor;
    padding: $boxSizeMargin * 1;
    > * {
      display: block;
    }
  }

  &--full,
  &--full-no-spacing {
    max-width: 100%;
  }
  &--full-no-spacing &__content {
    padding: 0;
  }

  &__body--opened {
    overflow: hidden;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .modal {
    min-width: 38em;
    &--full,
    &--full-no-spacing {
      max-width: 110ch;
    }
    &__close {
      padding: $boxSizeMargin * 1.75;
    }
    &__content {
      padding: $boxSizeMargin * 2;
    }
    &__title {
      padding-left: $boxSizeMargin * 2;
      padding-right: $boxSizeMargin * 2;
    }
  }
}
