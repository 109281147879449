@use "sass:math";

.link-list {
  margin: $boxSizeMargin 0;
  &__list {
    padding-left: 0;
    list-style-type: none;
    margin: math.div($boxSizeMargin, 2) 0;
  }
  &__title {
    font-size: $mediumSize;
    margin-bottom: $boxSizeMargin;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__item {
    position: relative;
    padding: math.div($boxSizeMargin, 4) 0;
    margin: 0;
  }
}
