@import "~./Fonts/fonts";

$fontFamily: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

$regular: 400;
$semiBold: 500;
$bold: 700;

$tinySize: 0.7rem;
$extraSmallSize: 0.8rem;
$smallSize: 0.9rem;
$regularSize: 1rem;
$mediumSize: 1.1rem;
$largeSize: 1.2rem;
$veryLargeSize: 1.4rem;
$extraLargeSize: 2rem;
$giantSize: 2.5rem;

$mediumIconSize: 1.6rem;
$smallIconSize: 1.2rem;

/* export variables used in `Typography.story.js` */
:export {
  family-roboto: $fontFamily;
  weight-regular: $regular;
  weight-semibold: $semiBold;
  weight-bold: $bold;
  size-tinysize: $tinySize;
  size-extrasmallsize: $extraSmallSize;
  size-smallsize: $smallSize;
  size-regularsize: $regularSize;
  size-mediumsize: $mediumSize;
  size-extralargesize: $extraLargeSize;
  size-giantsize: $giantSize;
  size-mediumiconsize: $mediumIconSize;
  size-smalliconsize: $smallIconSize;
}

html {
  font-size: 0.85em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  background: $backgroundColor;
  color: $fontColor;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $regular;
  line-height: 1.7;
}

.inverted {
  color: $backgroundColor;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

@media print {
  html {
    font-size: 10pt;
  }
}

@import "~theme/components/atoms/Typography/Body/Body";
@import "~theme/components/atoms/Typography/Heading/Heading";
@import "~theme/components/atoms/Typography/Link/Link";
@import "~theme/components/atoms/Typography/Link/Anchor";
@import "~theme/components/atoms/Typography/Price/Price";
