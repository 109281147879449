@use "sass:math";

.grid {
  display: flex;

  &__col {
    flex: 1;
    margin: 0 math.div($boxSizeMargin, 2);
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &--vertical {
    flex-direction: column;

    .grid__col {
      margin: math.div($boxSizeMargin, 2) 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
